<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="referenceNumberFilter"
          label="Filter by Receipt Reference Number"
          single-line
          clearable
          @input="debouncedFilter"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="paymentTransactionReferenceNumberFilter"
          label="Filter by Payment Transaction Reference Number"
          single-line
          clearable
          @input="debouncedFilter"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-select
          v-model="paymentStatusFilter"
          :items="paymentStatuses"
          label="Filter by Payment Status"
          clearable
          @change="debouncedFilter"
        ></v-select>
      </v-col>
      <v-col cols="12" md="6">
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="transactionInitiatedDisplay"
              label="Transaction Initiated Date Range"
              readonly
              v-bind="attrs"
              v-on="on"
              @click:clear="clearTransactionInitiated"
              clearable
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="transactionInitiated"
            range
            @change="menu1 = false; debouncedFilter()"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="6">
        <v-menu
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="transactionDateDisplay"
              label="Transaction Date Range"
              readonly
              v-bind="attrs"
              v-on="on"
              @click:clear="clearTransactionDate"
              clearable
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="transactionDate"
            range
            @change="menu2 = false; debouncedFilter()"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="filteredData"
      :loading="loading"
      :items-per-page="itemsPerPage"
      :page.sync="currentPage"
      @page-count="totalPages = $event"
      item-key="receiptReferenceNumber"
      class="elevation-1"
    ></v-data-table>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import debounce from "lodash/debounce";

export default {
  data() {
    return {
      referenceNumberFilter: '',
      paymentTransactionReferenceNumberFilter: '',
      paymentStatusFilter: '',
      transactionInitiated: [],
      transactionDate: [],
      transactionInitiatedDisplay: '',
      transactionDateDisplay: '',
      allData: [],
      filteredData: [],
      loading: false,
      headers: [
        { text: 'Receipt Reference Number', value: 'receiptReferenceNumber' },
        { text: 'Payment Transaction Reference Number', value: 'paymentTransactionReferenceNumber' },
        { text: 'Transaction Initiated Date', value: 'transactionInitiated' },
        { text: 'Payment Status', value: 'paymentStatus' },
        { text: 'Payment Amount', value: 'paymentAmount' },
        { text: 'Current Payment Status Transaction Date', value: 'transactionDate' }
      ],
      currentPage: 1,
      totalPages: 0,
      itemsPerPage: 5,
      paymentStatuses: [],
      menu1: false,
      menu2: false,
    };
  },
  created() {
    this.debouncedFilter = debounce(this.applyFilters, 300);
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    ...mapActions(["getPaymentDate"]),
    async fetchData() {
      this.loading = true;
      try {
        const data = await this.getPaymentDate();
        this.allData = data;
        this.paymentStatuses = [...new Set(data.map(item => item.paymentStatus))]; // Extract unique statuses
        this.applyFilters();
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        this.loading = false;
      }
    },
    applyFilters() {
      let data = this.allData;

      if (this.referenceNumberFilter) {
        data = data.filter(item => item.receiptReferenceNumber.includes(this.referenceNumberFilter));
      }
      if (this.paymentTransactionReferenceNumberFilter) {
        data = data.filter(item => item.paymentTransactionReferenceNumber.includes(this.paymentTransactionReferenceNumberFilter));
      }
      if (this.paymentStatusFilter) {
        data = data.filter(item => item.paymentStatus === this.paymentStatusFilter);
      }
      if (this.transactionInitiated.length === 2) {
        const [start, end] = this.transactionInitiated;
        data = data.filter(item => new Date(item.transactionInitiated) >= new Date(start) && new Date(item.transactionInitiated) <= new Date(end));
        this.transactionInitiatedDisplay = `${start} to ${end}`;
      } else {
        this.transactionInitiatedDisplay = '';
      }
      if (this.transactionDate.length === 2) {
        const [start, end] = this.transactionDate;
        data = data.filter(item => new Date(item.transactionDate) >= new Date(start) && new Date(item.transactionDate) <= new Date(end));
        this.transactionDateDisplay = `${start} to ${end}`;
      } else {
        this.transactionDateDisplay = '';
      }

      this.filteredData = data;
      this.currentPage = 1; // Reset to first page after filtering
    },
    clearTransactionInitiated() {
      this.transactionInitiated = [];
      this.debouncedFilter();
    },
    clearTransactionDate() {
      this.transactionDate = [];
      this.debouncedFilter();
    },
  },
};
</script>

