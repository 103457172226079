<template>
  <div>
    <v-col>
      Current Wallet Amount : {{walletAmount}}
    </v-col>
  </div>
  </template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  mounted () {
    const micsDataObj = JSON.parse(this.currentReceipt.extra)
    const micsData = micsDataObj['micsData']
    this.janrainId = micsData.janrainId
    this.campaignId = this.currentReceipt.campaignId
    this._getWalletAmount()
  },
  data() {
    return {
      janrainId: null,
      campaignId: null,
      walletAmount: 0
    };
  },
  computed: {
    ...mapGetters(["currentReceipt"])
  },
  methods: {
    ...mapActions(["getWalletAmount"]),
    async _getWalletAmount () {
      if (this.janrainId) {
        const data = await this.getWalletAmount({ consumerId: this.janrainId, campaignId: this.campaignId })
        this.walletAmount = `${data.currentCashbackBalance} ${data.currency}`
      }
    }
  }
};
</script>

<style>
.cause {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
