<template>
  <div>
    <v-autocomplete
      :prepend-icon="icons.mdiBellCancel"
      v-model="currentReceipt.reevaluateReason"
      :items="reEvaluationMessages"
      item-value="key"
      item-text="message"
      label="Re-Evaluate Reason"
      :required="true"
      :rules="[v => !!v || 'Please select a re evaluation reason']"
      :disabled="disabled"
    ></v-autocomplete>
    <v-text-field
      v-if="allowExtraField"
      v-model="currentReceipt.otherReason"
      outlined
      label="Additional Info"
      placeholder="Additional Info"
      hide-details
      class="mb-3"
    ></v-text-field>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mdiBellCancel } from "@mdi/js";
export default {
  data() {
    const reEvaluationMessages = localStorage.reEvaluationMessages ? JSON.parse(localStorage.reEvaluationMessages) : [];

    return {
      reEvaluationMessages,
      otherReason: '',
      icons: {
        mdiBellCancel
      }
    };
  },
  props: {
    disabled: Boolean
  },
  computed: {
    ...mapGetters(["currentReceipt"]),
    allowExtraField () {
      let currReason = this.currentReceipt.reevaluateReason
      return this.reEvaluationMessages.findIndex(x => x == currReason) == 12 || this.reEvaluationMessages.findIndex(x => x == currReason) == 11
    }
  }
};
</script>
