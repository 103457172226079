<template>
  <div
    v-viewer="{ inline: true, button: false, fullscreen: false, navbar: true, rotatable: false, scalable: false }"
  >
    <img v-for="(value, key) in productImageUrls"
      :key="key"
      style="width: 100%;"
      :src="value"
      :class="key > 0 ? 'd-none' : ''"
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import 'viewerjs/dist/viewer.css'
export default {
  components: {
  },
  props: {
  },
  methods: {
    inited(viewer) {
      this.$viewer = viewer
    },
    show() {
      this.$viewer.show()
    }
  },
  computed: {
    ...mapGetters(["currentReceipt"]),
    productImageUrls() {
      const productImageUrls = this.currentReceipt.productImageURL ? this.currentReceipt.productImageURL : []
      let extra = []
      if (this.currentReceipt.extra && typeof this.currentReceipt.extra == 'string') {
        extra = JSON.parse(this.currentReceipt.extra)
      } else if (this.currentReceipt.extra) {
        extra = this.currentReceipt.extra
      }
      const agentUploadedImages = extra.productImageUploadArray ? extra.productImageUploadArray : []

      const total = [...productImageUrls, ...agentUploadedImages]
      return total;
    }
  }
};
</script>