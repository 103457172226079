<template>
  <v-form
    ref="confirmForm"
    class="pl-5 pr-10 py-6"
    style="height: 100%; overflow-x: hidden"
  >
    <v-row
      justify="space-between"
      dense
    >
      <v-col cols="6">
        <v-btn
          @click="switchTab('receipt')"
          class="col-sm-12"
          :color="showDetail === 'receipt' ? 'primary' : ''"
          outlined
        >
          Receipt Details
        </v-btn>
      </v-col>
      <v-col
        cols="6"
        v-if="filterRole(['AGENTL1', 'AGENT', 'ADMIN', 'VIEWER'])"
      >
        <v-btn
          @click="switchTab('micsData')"
          class="col-sm-12"
          :color="showDetail === 'micsData' ? 'primary' : ''"
          outlined
        >
          MICS DATA
        </v-btn>
      </v-col>
      <v-col
        cols="6"
        v-if="filterRole(['AGENTL1', 'AGENT', 'ADMIN']) || (userRole === 'VIEWER' && market !== 'gr')"
      >
        <v-btn
          @click="switchTab('reuploadLink')"
          class="col-sm-12"
          :color="showDetail === 'reuploadLink' ? 'primary' : ''"
          outlined
        >
          Re Upload Links
        </v-btn>
      </v-col>
      <v-col
        cols="6"
        v-if="filterRole(['AGENTL1', 'AGENT', 'ADMIN', 'SERVICEOPDACH'])"
      >
        <v-btn
          @click="switchTab('agentsUploadFile')"
          class="col-sm-12"
          :color="showDetail === 'agentsUploadFile' ? 'primary' : ''"
          outlined
        >
          Agent Upload File
        </v-btn>
      </v-col>
      <v-col
        cols="6"
        v-if="filterRole(['AGENT', 'ADMIN', 'AGENTL1'])"
      >
        <v-btn
          @click="switchTab('processHistory')"
          class="col-sm-12"
          :color="showDetail === 'processHistory' ? 'primary' : ''"
          outlined
        >
          Process History
        </v-btn>
      </v-col>
      <v-col
        cols="6"
        v-if="filterRole(['AGENT', 'ADMIN', 'AGENTL1', 'VIEWER'])"
      >
        <v-btn
          @click="switchTab('paymentHistory')"
          class="col-sm-12"
          :color="showDetail === 'paymentHistory' ? 'primary' : ''"
          outlined
        >
          Payment History
        </v-btn>
      </v-col>
      <v-col
        cols="6"
        v-if="filterRole(['AGENT', 'ADMIN', 'AGENTL1'])"
      >
        <v-btn
          @click="switchTab('currentParticipation')"
          class="col-sm-12"
          :color="showDetail === 'currentParticipation' ? 'primary' : ''"
          outlined
        >
        Current Participation Details
        </v-btn>
      </v-col>
      <v-col
        cols="6"
        v-if="filterRole(['AGENT', 'ADMIN']) && campaignIsWallet()"
      >
        <v-btn
          @click="switchTab('walletTable')"
          class="col-sm-12"
          :color="showDetail === 'walletTable' ? 'primary' : ''"
          outlined
        >
          Wallet Table
        </v-btn>
      </v-col>
      <v-col
        cols="6"
        v-if="filterRole(['SERVICEOPDACH'])"
      >
        <v-btn
          @click="switchTab('address')"
          class="col-sm-12"
          :color="showDetail === 'address' ? 'primary' : ''"
          outlined
        >
          Address Details
        </v-btn>
      </v-col>
    </v-row>
    <div v-if="showDetail === 'micsData'">
      <v-row
        justify="space-between"
        style="padding-top: 20px"
        dense
      >
        <micsData />
      </v-row>
    </div>
    <div v-if="showDetail === 'reuploadLink'">
      <v-row
        justify="space-between"
        style="padding-top: 20px"
        dense
      >
        <ReuploadLinks />
      </v-row>
    </div>
    <div v-if="showDetail === 'agentsUploadFile'">
      <v-row
        justify="space-between"
        style="padding-top: 20px"
        dense
      >
        <AgentsUploadFile @close="$emit('close')" />
      </v-row>
    </div>
    <div v-if="showDetail === 'receipt'">
      <v-form
        id="receiptForm"
        ref="receiptForm"
        class="pl-5 pr-10 py-6"
        style="height: 100%; overflow-x: hidden"
      >
        <v-row
          justify="space-between"
          style="padding-top: 20px"
          dense
        >
          <v-col cols="12">
            <Pick-Date :disabled="filterRole(['AGENTL1', 'VIEWER', 'SERVICEOPDACH'])"></Pick-Date>
          </v-col>
          <v-col cols="4">
            <Receipt-Id :disabled="filterRole(['AGENTL1', 'VIEWER', 'SERVICEOPDACH'])"></Receipt-Id>
          </v-col>
          <v-col cols="4">
            <Receipt-Total :disabled="filterRole(['AGENTL1', 'VIEWER', 'SERVICEOPDACH'])"></Receipt-Total>
          </v-col>
          <v-col cols="4">
            <Country :disabled="filterRole(['AGENTL1', 'VIEWER', 'SERVICEOPDACH'])"></Country>
          </v-col>
          <v-col cols="12">
            <Retailer
              :disabled="filterRole(['AGENTL1', 'VIEWER', 'SERVICEOPDACH'])"
              @foundProducts="foundProducts"
            ></Retailer>
          </v-col>
          <v-col cols="6">
            <Cashback-Amount></Cashback-Amount>
          </v-col>
        </v-row>
      </v-form>
      <v-row justify="space-between">
        <v-col
          cols="12"
        >
          <v-card>
            <v-card-title class="pb-0" v-if="!filterRole(['SERVICEOPDACH'])">
              <h3 class="headline mb-0">
                Incentivized Products
              </h3>
            </v-card-title>
            <v-card-text>
              <Products
                :disabled="filterRole(['AGENTL1', 'VIEWER', 'SERVICEOPDACH'])"
                :selected="found"
                product-category="participatingProducts"
              >
              </products>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          cols="12"
        >
        <v-expansion-panels
          multiple
          v-if="!filterRole(['VIEWER', 'SERVICEOPDACH'])"
        >
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h3 class="headline mb-0">
                All Other P&G Products
              </h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <Products
                :disabled="filterRole(['AGENTL1', 'VIEWER'])"
                :selected="found"
                product-category="pgProducts"
              >
              </products>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h3 class="headline mb-0">
                Competitor Products
              </h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <Products
                :disabled="filterRole(['AGENTL1', 'VIEWER'])"
                :selected="found"
                product-category="compProducts"
              >
              </products>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h3 class="headline mb-0">
                Other Products
              </h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <OtherProducts
                :disabled="filterRole(['AGENTL1', 'VIEWER'])"
              >
              </OtherProducts>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        </v-col>
        <v-col cols="12">
          <RejectReasonsDropDown v-if="!filterRole(['AGENTL1', 'VIEWER'])"></RejectReasonsDropDown>
          <ReevaluateReasonsDropDown v-if="filterRole(['AGENTL1'])"></ReevaluateReasonsDropDown>
        </v-col>
        <v-col cols="12">
          <handle-receipt v-if="!filterRole(['VIEWER'])" :disabled="filterRole(['AGENTL1', 'VIEWER'])"></handle-receipt>
        </v-col>
      </v-row>
    </div>
    <div v-else-if="showDetail === 'processHistory'">
      <Process-History></Process-History>
    </div>
    <div v-else-if="showDetail === 'paymentHistory'">
      <Payment-History></Payment-History>
    </div>
    <div v-else-if="showDetail === 'currentParticipation'">
      <Current-Participation></Current-Participation>
    </div>
    <div v-else-if="showDetail === 'walletTable'">
      <WalletData />
    </div>
    <div v-else-if="showDetail === 'address'">
      <AddressDetails />
    </div>
    <v-row class="justify-end mt-10">
      <v-btn
        color="warning"
        @click="$emit('close')"
      >
        Cancel
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>
import { mapGetters } from "vuex";

import RejectReasonsDropDown from "./ReceiptDetails/RejectReasonsDropDown";
import ReevaluateReasonsDropDown from "./ReceiptDetails/ReevaluateReasonsDropDown";
import ReceiptId from "./ReceiptDetails/ReceiptId";
import Country from "./ReceiptDetails/Country";
import PickDate from "./ReceiptDetails/PickDate";
import ReceiptTotal from "./ReceiptDetails/ReceiptTotal";
import Retailer from "./ReceiptDetails/Retailer";
import HandleReceipt from "./ReceiptDetails/HandleReceipt";
import ProcessHistory from "./ReceiptDetails/ProcessHistory";
import PaymentHistory from "./ReceiptDetails/PaymentHistory";
import CurrentParticipation from "./ReceiptDetails/CurrentParticipation";
import Products from "./ReceiptDetails/Products";
import MicsData from "./ReceiptDetails/MicsData";
import ReuploadLinks from "./ReceiptDetails/ReuploadLinks";
import AgentsUploadFile from "./ReceiptDetails/AgentsUploadFile";
import CashbackAmount from "./ReceiptDetails/CashbackAmount";
import OtherProducts from './ReceiptDetails/OtherProducts.vue';
import WalletData from "./ReceiptDetails/WalletData.vue";
import endpointsConfig from "./../../store/endpoints";
import AddressDetails from "./ReceiptDetails/AddressDetails";

export default {
  data() {
    const user = JSON.parse(localStorage.getItem("user"));
    const userRole = user.role;
    return {
      showDetail: "receipt",
      tab: null,
      userRole,
      found: null,
    };
  },
  props: {
    serviceDash: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  components: {
    PickDate,
    RejectReasonsDropDown,
    ReevaluateReasonsDropDown,
    ReceiptId,
    Country,
    ReceiptTotal,
    Retailer,
    HandleReceipt,
    ProcessHistory,
    Products,
    PaymentHistory,
    MicsData,
    ReuploadLinks,
    AgentsUploadFile,
    CashbackAmount,
    OtherProducts,
    CurrentParticipation,
    WalletData,
    AddressDetails
  },
  computed: {
    ...mapGetters(["currentReceipt", "market"])
  },
  provide() {
    return {
      validateForm: this.validateForm
    };
  },
  methods: {
    filterRole(roles) {
      return roles.includes(this.userRole)
    },
    campaignIsWallet () {
      const currentCampaignConfig = endpointsConfig[this.market]
      if (currentCampaignConfig) return currentCampaignConfig.walletCampaign
      return false
    },
    foundProducts(v) {
      this.found = v
    },
    switchTab(status) {
      this.showDetail = status;
    },
    validateForm() {
      return this.$refs.receiptForm.validate();
    }
  }
};
</script>

<style>

</style>
