
export default {
  snackBar: {
    message: "",
    timeout: 1500,
    type: "error"
  },
  campaignNameMap: {
    "30061": "MBCI Mar 24",
    "30062": "MBCI Mar 24",
    "30063": "MBCI Mar 24",
    "30103": "ALWAYS Mar 24",
    "30067": "Olympics (ARI,JAR) Mar 24",
    "30057": "ALWAYS Mar 24",
    "30046": "MBCI Mar 24",
    "30047": "MBCI Mar 24",
    "30048": "MBCI Mar 24",
    "30049": "MBCI Mar 24",
    "30050": "MBCI Rossmann Mar24",
    "30051": "MBCI Rossmann Mar24",
    "30052": "MBCI Rossmann Mar24",
    "30053": "MBCI Rossmann Mar24",
    "30054": "ALWAYS Mar 24",
    "30064": "MBCI Mar 24",
    "30065": "MBCI Mar 24",
    "30066": "MBCI Mar 24",
    "30104": "ALWAYS Mar 24",
    "30068": "Olympics (ARI,JAR) Mar 24"
  },
  showSnackBar: false,
  isAuthenticated: false,
  userToken: "",
  userRole: "",
  userName: "",
  market: "",
  darkTheme: false,
  autoPilotMode: false,
  currentReceipt: {},
  showDialogue: false,
  prevPartcipations: [],
  processHistory: [],
  paymentHistory: [],
  deviceInfo: {},
  campaigns: [],
  routes: {
    dashboard: {
      accessRoles: ["ADMIN", "OPERATOR", "TESTER"]
    },
    agent: {
      accessRoles: ["AGENT", "ADMIN", "TESTER", "AGENTL1", "VIEWER"]
    },
    consumerReceipts: {
      accessRoles: ["ADMIN", "AGENT", "AGENTL1"]
    },
    holdOnReceipts: {
      accessRoles: ["ADMIN", "AGENT"]
    },
    paymentDates: {
      accessRoles: ["ADMIN", "AGENT"]
    },
    service: {
      accessRoles: ["ADMIN", "SERVICEOP", "SERVICEOPDACH"],
      accessRegions: ["hu", "pl", "cz", "sk"]
    },
    createManualParticipation: {
      accessRoles: ["ADMIN", "AGENT"]
    },
  },
  globalCampaignId: "2380"
};
