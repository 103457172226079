<template>
  <v-col>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              Email
            </th>
            <th class="text-left">
              Mobile
            </th>
            <th class="text-left">
              IBAN
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
          >
            <td>{{ currentParticipation.email }}</td>
            <td>{{ currentParticipation.mobile }}</td>
            <td>{{ currentParticipation.iban }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-col>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  data () {
    return {
      currentParticipation: {
        email: '',
        mobile: '',
        iban: ''
      }
    };
  },
  computed: {
    ...mapGetters(['currentReceipt'])
  },
  mounted () {
    const extra = this.currentReceipt.extra ? JSON.parse(this.currentReceipt.extra) : {};
    this.currentParticipation.email = extra.userEmail;
    this.currentParticipation.mobile = extra.userMobile;
    this.currentParticipation.iban = extra.userIban;
  }
};
</script>
