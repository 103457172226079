import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import preset from "./default-preset/preset";
import store from "../../store";

Vue.use(Vuetify);
export default new Vuetify({
  preset,
  icons: {
    iconfont: "mdiSvg"
  },
  theme: {
    options: {
      customProperties: true,
      variations: false
    },
    dark: store.getters.darkTheme
  }
});
