<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="360"
    :right="$vuetify.rtl"
    @input="(val) => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link to="/" class="d-flex align-center text-decoration-none">
        <v-img
          :src="require('@/assets/images/misc/logo-red.png')"
          max-height="50px"
          max-width="50px"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
        ></v-img>
        <v-slide-x-transition>
          <h2 class="app-title text--primary">
            JUSTSNAP
          </h2>
        </v-slide-x-transition>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list expand shaped class="vertical-nav-menu-items pr-5">
      <v-list-item
        class="vertical-nav-menu-link success white--text mb-4"
        disabled
        rounded
      >
        <v-list-item-icon>
          <v-icon class="mx-auto">
            {{ icons.mdiHomeOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          {{ dashName + " (" + marketName + ")" }}
        </v-list-item-title>
      </v-list-item>
      <nav-menu-link
        v-show="hasAccess('dashboard')"
        title="Dashboard"
        :to="{ name: 'dashboard' }"
        :icon="icons.mdiMagnifyExpand"
      ></nav-menu-link>
      <nav-menu-link
        v-show="hasAccess('agent')"
        title="Search by Reference number"
        :to="{ name: 'agent' }"
        :icon="icons.mdiMagnifyExpand"
      ></nav-menu-link>
      <nav-menu-link
        v-show="hasAccess('consumerReceipts') || (userRole === 'VIEWER' && market !== 'gr')"
        title="Search by consumer ID or email"
        :to="{ name: 'consumerReceipts' }"
        :icon="icons.mdiAccountSearchOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-show="hasAccess('holdOnReceipts')"
        title="View and Update Hold On Receipts"
        :to="{ name: 'holdOnReceipts' }"
        :icon="icons.mdiAccountSearchOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-show="hasAccess('createManualParticipation')"
        title="Create Manual Participation"
        :to="{ name: 'createManualParticipation' }"
        :icon="icons.mdiAccountSearchOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-show="hasAccess('paymentDates')"
        title="View Payment History (Wallet)"
        :to="{ name: 'paymentDates' }"
        :icon="icons.mdiAccountSearchOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-show="hasAccess('service')"
        title="Service Dashboard"
        :to="{ name: 'service' }"
        :icon="icons.mdiAccountSearchOutline"
      ></nav-menu-link>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiHomeOutline,
  mdiAccountSearchOutline,
  mdiMagnifyExpand
} from "@mdi/js";
import NavMenuLink from "./components/NavMenuLink.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    NavMenuLink
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  computed: {
    ...mapGetters(["routes", "market"]),
    dashName() {
      if (this.userRole === "AGENTL1") return "AGENT 1 DASHBOARD"
      else if (this.userRole === "AGENT") return "AGENT 2 DASHBOARD"
      else return this.userRole
    },
    marketName() {
      const markets = {
        'de': 'DACH',
        'gr': 'Greece',
        'tr': 'Turkey',
        'hu': 'Hungary',
        'pl': 'Poland',
        'cz': 'Czech',
        'sk': 'Slovakia',
        'ro': 'Romania',
      }
      return markets[this.market]
    }
  },
  methods: {
    hasAccess(route) {
      return this.routes[route].accessRoles.includes(this.userRole) || this.routes[route].accessRegions?.includes(this.market);
    },
  },

  setup() {
    const userRole = JSON.parse(localStorage.user).role;
    return {
      userRole,
      icons: {
        mdiHomeOutline,
        mdiAccountSearchOutline,
        mdiMagnifyExpand
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, "background");
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {
  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
