<template>
  <div class="mt-8">
    <div v-if="currentReceipt.address">
      <v-row>
        <v-col cols="6">
          <v-text-field
            label="First Name"
            v-model="firstName"
            :prepend-icon="mdiAccount"
            outlined
            readonly
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Last Name"
            v-model="lastName"
            :prepend-icon="mdiAccount"
            outlined
            readonly
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            label="Street"
            v-model="strasse"
            :prepend-icon="mdiRoadVariant"
            outlined
            readonly
            auto-grow
            rows="1"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="House Number"
            v-model="hausnr"
            :prepend-icon="mdiHome"
            outlined
            readonly
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Postcode"
            v-model="plz"
            :prepend-icon="mdiNumeric"
            outlined
            readonly
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="City"
            v-model="ort"
            :prepend-icon="mdiCity"
            outlined
            readonly
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Country"
            v-model="land"
            :prepend-icon="mdiEarth"
            outlined
            readonly
          />
        </v-col>
      </v-row>
    </div>
    <div v-else>Address details could not be found</div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { mdiAccount, mdiRoadVariant, mdiHome, mdiNumeric, mdiCity, mdiEarth } from "@mdi/js"

export default {
  computed: {
    ...mapGetters(["currentReceipt"])
  },
  data: () => ({
    firstName: '',
    lastName: '',
    strasse: '',
    hausnr: '',
    plz: '',
    ort: '',
    land: '',
    mdiAccount,
    mdiRoadVariant,
    mdiHome,
    mdiNumeric,
    mdiCity,
    mdiEarth
  }),
  mounted () {
    const address = JSON.parse(this.currentReceipt.address)
    if (address) {
      this.firstName = address.firstName
      this.lastName = address.lastName
      this.strasse = address.strasse
      this.hausnr = address.hausnr
      this.plz = address.plz
      this.ort = address.ort
      this.land = address.land
    }
  }
};
</script>
