<template>
  <v-card v-if="!['AGENTL1'].includes(userRole)">
    <v-card-title class="align-start">
      <span>Statistics</span>
      <v-spacer></v-spacer>
      <v-btn
        icon
        small
        class="me-n3 mt-n2"
        @click="getStatistic"
      >
        <v-icon>
          {{ icons.mdiRefresh }}
        </v-icon>
      </v-btn>
    </v-card-title>
    <div v-if="['ADMIN', 'OPERATOR', 'TESTER'].includes(userRole)">
      <v-card-text>
        <v-list class="pb-0">
          <v-list-item>
            <div class="d-flex align-center flex-grow-1 flex-wrap">
              <div class="me-2">
                <div class="font-weight-semibold">
                  <span class="text--primary text-base me-1 success">{{
                    pendingInPreConfirm
                  }}</span>
                  <span class="text--primary text-base me-1">Pre-Confirm receipts in the queue.</span>
                </div>
              </div>
              <v-spacer></v-spacer>
            </div>
          </v-list-item>
        </v-list>
      </v-card-text>
    </div>
    <div v-if="['AGENT', 'ADMIN'].includes(userRole)">
      <v-card-text>
        <v-list class="pb-0">
          <v-list-item>
            <div class="d-flex align-center flex-grow-1 flex-wrap">
              <div class="me-2">
                <div class="font-weight-semibold">
                  <span class="text--primary text-base me-1 success">{{
                    pendingInReEvaluation
                  }}</span>
                  <span class="text--primary text-base me-1">Re-Evaluation receipts in the queue.</span>
                </div>
              </div>
              <v-spacer></v-spacer>
            </div>
          </v-list-item>
        </v-list>
      </v-card-text>
    </div>
    <div v-if="!['AGENT'].includes(userRole)">
      <v-card-title class="align-start">
        <span>Performance</span>
        <v-spacer></v-spacer>
        <v-select
          v-model="performanceType"
          :items="performanceTypes"
        ></v-select>
      </v-card-title>
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Date</th>
                <th class="text-left">Receipts Read</th>
              </tr>
            </thead>
            <tbody v-if="performanceType == 'monthly'">
              <tr v-for="(item, idx) in monthlyPerformance" :key="idx">
                <td>{{ formatDate(item.month) }}</td>
                <td>{{ item.count }}</td>
              </tr>
            </tbody>
            <tbody v-else-if="performanceType == 'daily'">
              <tr v-for="(item, idx) in dailyPerformance" :key="idx">
                <td>{{ formatDate(item.day) }}</td>
                <td>{{ item.count }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div v-if="performanceType == 'dailyGraph'">
          <apexchart type="line" :options="chartOptions" :series="series"></apexchart>
        </div>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { mdiRefresh } from "@mdi/js";
import { mapActions } from "vuex";
export default {
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    const user = JSON.parse(localStorage.getItem("user"));
    const userRole = user.role;
    return {
      userRole,
      dailyPerformance: [],
      monthlyPerformance: [],
      pendingInPreConfirm: 0,
      pendingInReEvaluation: 0,
      performanceType: "monthly",
      performanceTypes: [
        { text: "Monthly Table", value: "monthly" },
        { text: "Daily Table", value: "daily" },
        { text: "Daily Graph", value: "dailyGraph" },
      ],
      icons: {
        mdiRefresh,
      },
      series: [],
      chartOptions: {
        xaxis: {
          categories: [],
          title: {
            text: "Date",
          },
        },
        yaxis: {
          title: {
            text: "Count",
          },
        },
      },
    };
  },
  methods: {
    ...mapActions(["dashboarderStatistics"]),
    getStatistic() {
      this.dashboarderStatistics().then((res) => {
        this.dailyPerformance = res.dailyPerformance;
        this.monthlyPerformance = res.monthlyPerformance;
        this.pendingInPreConfirm = res.preConfirmedCount;
        this.pendingInReEvaluation = res.preEvalutedCount;

        // Set the chart details
        const labels = this.dailyPerformance.map((item) => this.$moment(item.day).format('DD/MM'));
        const data = this.dailyPerformance.map((item) => item.count);

        this.series = [
          {
            name: "Count",
            data,
          },
        ];
        this.chartOptions.xaxis.categories = labels;
      });
    },
    formatDate (dateString) {
      let formattedDate;

      // Detect the format by counting the number of slashes and the length
      const slashCount = (dateString.match(/\//g) || []).length;

      if (slashCount === 2) {
        // The format is YYYY/MM/DD
        const date = this.$moment(dateString, 'YYYY/MM/DD');
        formattedDate = date.format('dddd DD/MM/YYYY');
      } else if (slashCount === 1) {
        // The format is YYYY/MM
        const date = this.$moment(dateString, 'YYYY/MM');
        formattedDate = date.format('MMMM YYYY MM/YYYY');
      } else {
        return 'Invalid format';
      }

      return formattedDate;
    }
  },
  mounted () {
    this.getStatistic();
  }
};
</script>
