import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

import Login from "@/views/pages/Login.vue";
import ForgotPassword from "@/views/pages/ForgotPassword.vue";
import ResetPassword from "@/views/pages/ResetPassword.vue";
import Dashboard from "@/views/dashboard/Dashboard.vue";
import ConsumerReceipts from "@/views/dashboard/ConsumerReceipts";
import HoldOnReceipts from "@/views/dashboard/HoldOnReceipts"
import PaymentDates from "@/views/dashboard/PaymentDates"
import Agent from "@/views/dashboard/Agent.vue";
import Error404 from "@/views/Error.vue";
import Profile from "@/views/pages/account-settings/AccountSettings.vue";
import ServiceDashboard from "@/views/dashboard/ServiceDashboard.vue";
import CreateManualParticipation from "@/views/dashboard/CreateManualParticipation.vue";
import { updateAuthToken } from "../plugins/axios";

Vue.use(VueRouter);

const isAuthenticated = (to, from, next) => {
  const routeConfig = store.getters.routes[to.name];
  if (store.getters.isAuthenticated || localStorage.isAuthenticated) {
    store.commit("setIsAuthenticated", true);
    if (localStorage.user) {
      const user = JSON.parse(localStorage.user);
      console.log("User", user)
      store.commit("setUserName", user.userName);
      store.commit("setMarket", user.market);
      store.commit("setUserRole", user.role);
      store.commit("setUserToken", user.userToken);
      updateAuthToken(user.userToken);
    }
    if (routeConfig && routeConfig.accessRoles) {
      const userRole = store.getters.userRole;
      const userRegion = store.getters.market
      console.log("routeConfig", routeConfig, userRole)
      if (routeConfig.accessRoles.includes(userRole) || routeConfig.accessRegions?.includes(userRegion)) {
        next();
      } else {
        next({ name: "error-404" });
      }
    }
    next();
    return;
  }
  next("/login");
};

const routes = [
  {
    path: "/forgotPassword",
    name: "forgotPassword",
    component: ForgotPassword,
    meta: {
      layout: "blank"
    }
  },
  {
    path: "/resetPassword",
    name: "resetPassword",
    component: ResetPassword,
    meta: {
      layout: "blank"
    }
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      layout: "blank"
    }
  },
  {
    path: "/",
    redirect: "dashboard",
    beforeEnter: isAuthenticated
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    beforeEnter: isAuthenticated
  },
  {
    path: "/consumerReceipts",
    name: "consumerReceipts",
    component: ConsumerReceipts,
    beforeEnter: isAuthenticated
  },
  {
    path: "/holdOnReceipts",
    name: "holdOnReceipts",
    component: HoldOnReceipts,
    beforeEnter: isAuthenticated
  },
  {
    path: "/paymentDates",
    name: "paymentDates",
    component: PaymentDates,
    beforeEnter: isAuthenticated
  },
  {
    path: "/createManualParticipation",
    name: "createManualParticipation",
    component: CreateManualParticipation,
    beforeEnter: isAuthenticated
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
    beforeEnter: isAuthenticated
  },
  {
    path: "/agent",
    name: "agent",
    component: Agent,
    beforeEnter: isAuthenticated
  },
  {
    path: "/service",
    name: "service",
    component: ServiceDashboard,
    beforeEnter: isAuthenticated
  },
  {
    path: "/error-404",
    name: "error-404",
    component: Error404,
    meta: {
      layout: "blank"
    }
  },
  {
    path: "*",
    redirect: "error-404"
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
