<template>
  <v-col v-if="paymentHistory !== {}">
    <v-data-table
      :headers="paymentHeaders"
      :disable-pagination="true"
      :hide-default-footer="true"
      :items="[paymentHistory]"
      dense
    >
      <template #[`item.created_at`]="{ item }">
        {{ getDateTime(item.created_at) }}
      </template>
      <template #[`item.updated_at`]="{ item }">
        {{ getDateTime(item.updated_at) }}
      </template>
      <template #[`item.status`]="{ item }">
        <v-chip color="info" text-color="white">
          {{ item.status ? item.status : item.state }}
        </v-chip>
      </template>
    </v-data-table>
  </v-col>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      paymentHeaders: [
        { text: "Status", value: "status" },
        { text: "Created At", value: 'created_at', },
        { text: "Updated At", value: "updated_at" },
        { text: "Payment Reference No.", value: "id" },
      ]
    };
  },
  computed: {
    ...mapGetters(["paymentHistory"]),
  },
  methods: {
    getDateTime(date) {
      if (date)
        return this.$moment.parseZone(date).format("DD/MM/YYYY, HH:mm:ss");
      else return "";
    },
  },
};
</script>
