<template>
  <div>
    <v-col sm="sm-10">
      <v-autocomplete
        label="Products"
        :prepend-icon="icons.mdiFormatListText"
        :items="products"
        item-text="id"
        item-value="id"
        max-height="auto"
        search-input.sync="id"
        multiple
        :filter="searchProduct"
        :disabled="disabled"
      >
        <template #item="data">
          <div style="width:100%;">
            <v-list-item
              @click.native.stop.prevent="addProduct(data)"
              class="d-flex justify-space-between"
            >
              <div>
                <span style="display:inline-block;min-width:18ch;">{{ data.item.ean }}</span>
                <v-chip class="ma-2" color="blue" text-color="white">
                  <span class="black--text">{{ data.item.name }}</span>
                </v-chip>
              </div>
              <span>
                <v-btn icon @click.stop.prevent="addProduct(data, true)">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
                <v-btn icon @click.stop.prevent="removeProduct(data, true)">
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
              </span>
            </v-list-item>
          </div>
        </template>
      </v-autocomplete>
      <v-data-table
        dense
        :items="otherProducts"
        :disable-pagination="true"
        :hide-default-footer="true"
        :headers="headers"
        no-data-text="No Products Selected"
        :item-key="new Date().getMilliseconds().toString()"
        class="elevation-1"
      >
        <template #[`item.brand`]="{ item }">
          <div>
            <v-text-field
              required
              @input="changeBrand($event, item)"
              v-model="item.brand"
            ></v-text-field>
          </div>
        </template>
        <template #[`item.name`]="{ item }">
          <div>
            <v-text-field
              required
              @input="changeName($event, item)"
              v-model="item.name"
            ></v-text-field>
          </div>
        </template>
        <template #[`item.price`]="{ item }">
          <div>
            <v-text-field
              required
              @input="changePrice($event, item)"
              v-model="item.price"
            ></v-text-field>
          </div>
        </template>
        <template #[`item.rppData`]="{ item }">
          <div>
            <v-autocomplete
              :items="rppItems"
              v-model="item.rpp"
              @change="updateRpp($event, item)"
              dense
            >
              <template #selection="{ item }">
                {{ item.item }}
              </template>
              <template #item="{ item }">
                <div style="width: 100%">
                  <v-list-item
                    class="d-flex justify-space-between"
                  >
                    <div>
                      <span>{{ item.item }}</span>
                      <v-chip color="blue" text-color="black">
                        <span class="black--text">{{ item.price }}</span>
                      </v-chip>
                    </div>
                  </v-list-item>
                </div>
              </template>
            </v-autocomplete>
          </div>
        </template>
        <template #[`item.action`]="{ item }">
          <v-icon small class="mr-2" @click="addProduct(item, true)">
            {{ icons.mdiPlus }}
          </v-icon>
          <v-icon small @click="removeProduct(item, true)">
            {{ icons.mdiMinus }}
          </v-icon>
        </template>
      </v-data-table>
    </v-col>
  </div>
</template>

<script>
import { mdiPlus, mdiMinus, mdiFormatListText } from "@mdi/js";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["currentReceipt"])
  },
  data() {
    const products = localStorage.otherProducts ? JSON.parse(localStorage.otherProducts) : [];
    const selectedOtherProducts = this.$store.getters.currentReceipt.products ? this.$store.getters.currentReceipt.products[this.productCategory] : [];
    const rppItems = this.$store.getters.currentReceipt.rppData ? JSON.parse(this.$store.getters.currentReceipt.rppData) : [];
    return {
      rppItems,
      otherProducts: selectedOtherProducts || [],
      products,
      icons: {
        mdiPlus,
        mdiMinus,
        mdiFormatListText
      },
      headers: [
        {
          text: "Brand",
          align: "left",
          sortable: false,
          value: "brand"
        },
        {
          text: "Product",
          align: "left",
          sortable: false,
          value: "name"
        },
        {
          text: "RPP",
          align: "left",
          sortable: false,
          value: "rppData"
        },
        {
          text: "Price",
          align: "left",
          sortable: false,
          value: "price"
        },
        {
          text: "Count",
          align: "left",
          sortable: false,
          value: "count"
        },
        {
          text: "Actions",
          value: "action",
          sortable: false
        }
      ]
    };
  },
  props: {
    disabled: Boolean,
    productCategory: {
      type: String,
      default: "otherProducts"
    }
  },
  methods: {
    addProduct(data, status) {
      const product =
        data && data.item
          ? data.item
          : data
          ? data
          : {
              name: "",
              brand: "",
              price: "",
              count: 1,
              rpp: ''
            };
      const isFound = this.otherProducts.findIndex(item => item.name === product.name);
      if (isFound >= 0) {
        this.otherProducts[isFound].count++;
      } else {
        this.otherProducts.push({
          name: product.name,
          brand: product.brand ? product.brand : "",
          id: product.id ? product.id: null,
          count: 1,
          price: product.price || 0
        });
      }
      this.currentReceipt.products[this.productCategory] = this.otherProducts || [];
    },
    changePrice(event, product) {
      const isFound = this.otherProducts.findIndex(item => item.name === product.name);
      if (isFound >= 0) {
        this.otherProducts[isFound].price = event;
      }
      this.currentReceipt.products[this.productCategory] = this.otherProducts;
    },
    changeName(event, product) {
      const isFound = this.otherProducts.findIndex(item => item.name === product.name);
      if (isFound >= 0) {
        this.otherProducts[isFound].name = event;
      }
      this.currentReceipt.products[this.productCategory] = this.otherProducts;
    },
    changeBrand(event, product) {
      const isFound = this.otherProducts.findIndex(item => item.name === product.name);
      if (isFound >= 0) {
        this.otherProducts[isFound].brand = event;
      }
      this.currentReceipt.products[this.productCategory] = this.otherProducts;
    },

    removeProduct(data, status) {
      const product = status ? data : data.item;
      const isFound = this.otherProducts.findIndex(item => item.name === product.name);
      if (isFound >= 0) {
        if (this.otherProducts[isFound].count > 1) {
          this.otherProducts[isFound].count--;
        } else {
          this.otherProducts.splice(isFound, 1);
        }
      }
      this.currentReceipt.products[this.productCategory] = this.otherProducts;
    },

    searchProduct(item, inpt) {
      return item.name.toUpperCase().includes(inpt.toUpperCase());
    },
    updateRpp(event, product) {
      const isFound = this.otherProducts.findIndex(item => item.id === product.id);
      if (isFound >= 0) {
        this.otherProducts[isFound].rpp = event;
      }
      this.currentReceipt.products[this.productCategory] = this.otherProducts;
    }
  }
};
</script>
