<template>
  <v-row>
    <v-col cols="6" md="6">
      <search-reference-number v-if="userRole !== 'OPERATOR'"></search-reference-number>
    </v-col>
    <v-col cols="12" md="6" v-if="userRole != 'VIEWER'">
      <Dashboard-Statistics-Card></Dashboard-Statistics-Card>
    </v-col>
    <receipt-dialogue :showDialogue="getShowDialogue" @close="showDialogue = false" v-if="showDialogue" />
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

// demos
import ReceiptDialogue from "./ReceiptDialogue.vue";
import SearchReferenceNumber from "@/components/SearchReferenceNumber.vue";
import DashboardStatisticsCard from "./DashboardStatisticsCard.vue";

export default {
  computed: {
    ...mapGetters(["showDialogue", "userRole"]),
    getShowDialogue: {
      get() {
        return this.showDialogue;
      },
    },
  },
  components: {
    ReceiptDialogue,
    SearchReferenceNumber,
    DashboardStatisticsCard,
  },
};
</script>
