<template>
  <div class="container">
    <div
      class="box"
      v-for="(micsData, index) of micsDataArr"
      :key="index"
    >
      <span class="m-0 center bold" >{{micsData.label}}: </span>
      <p class="m-0 center bold">{{micsData.value}}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "micsData",
  data() {
    return {
      micsDataArr: [],
      micsKeyToLabel: {
        janrainId: "Janrain Id",
        marketingProgramNumber: "Marketing Program Number",
        campaignName: "Campaign Name",
        discountValue: "Discount Value",
        campaignDescription: "Campaign Description",
        campaignStartDate: "Start Date",
        campaignEndDate: "End Date"
      }
    }
  },
  mounted () {
    const micsDataObj = JSON.parse(this.currentReceipt.extra)
    const micsDataKeys = Object.keys(micsDataObj['micsData'])

    this.micsDataArr = micsDataKeys.filter(item => this.micsKeyToLabel[item]).map(item => {
      return {
        label: this.micsKeyToLabel[item],
        value: micsDataObj['micsData'][item]
      }
    })
  },
  computed: {
    ...mapGetters(["currentReceipt"])
  }
}
</script>


<style lang="scss">

.container {
  display: flex;
  flex-direction: column;
}

.box {
  margin: 10px 0px;
  border: 1px solid gray;
  padding-inline: 1rem;
  border-radius: 16px;
}

.bold {
  font-weight: bold;
}

.center {
  text-align: center;
}

.m-0 {
  margin: 0 !important;
}

</style>
