<template>
  <v-text-field
    required
    outlined
    :prepend-icon="icons.mdiCalculator"
    v-model="currentReceipt.total"
    label="Receipt Total"
    :rules="[v => !!v || 'Please enter the receipt total']"
    :disabled="disabled"
    dense
  ></v-text-field>
</template>

<script>
import { mapGetters } from "vuex";
import { mdiCalculator } from "@mdi/js";
export default {
  data() {
    return {
      icons: {
        mdiCalculator
      }
    };
  },
  props: {
    disabled: Boolean
  },
  computed: {
    ...mapGetters(["currentReceipt"])
  }
};
</script>
