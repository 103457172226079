<template>
  <v-autocomplete
    :prepend-icon="icons.mdiBellCancel"
    v-model="currentReceipt.rejectReason"
    :items="rejectReasons"
    item-value="key"
    item-text="message"
    label="Reject Reason"
    :required="true"
    :rules="[v => !!v || 'Please select a reject reason']"
    :disabled="disabled"
  ></v-autocomplete>
</template>

<script>
import { mapGetters } from "vuex";
import { mdiBellCancel } from "@mdi/js";
export default {
  data() {
    const rejectReasons = localStorage.rejectReasons ? JSON.parse(localStorage.rejectReasons) : [];
    return {
      rejectReasons,
      icons: {
        mdiBellCancel
      }
    };
  },
  mounted () {
    if (this.currentReceipt.status == "Pre-Confirmed") {
      this.currentReceipt.rejectReason = "";
    }
  },
  props: {
    disabled: Boolean
  },
  computed: {
    ...mapGetters(["currentReceipt"])
  }
};
</script>
