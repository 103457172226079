<template>
  <v-fade-transition mode="out-in">
    <v-icon :key="darkTheme" @click="switchTheme(darkTheme)">
      {{ darkTheme ? icons.mdiWeatherSunny : icons.mdiWeatherNight }}
    </v-icon>
  </v-fade-transition>
</template>

<script>
import { mdiWeatherNight, mdiWeatherSunny } from "@mdi/js";
import { mapActions, mapGetters } from "vuex";

export default {
  methods: {
    ...mapActions(["setTheme"]),
    switchTheme(status) {
      this.setTheme(status);
    }
  },
  setup() {
    return {
      icons: {
        mdiWeatherNight,
        mdiWeatherSunny
      }
    };
  },
  computed: {
    ...mapGetters(['darkTheme'])
  }
};
</script>

<style></style>
