<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <div class="image_block">
        <img :src="require(`@/assets/images/misc/js-logo.png`)" alt="Justsnap">
      </div>
      <v-card class="auth-card">
        <v-card-text>
          <v-form ref="form" v-model="valid" @keyup.native.enter="loginUser">
            <v-text-field
              v-model="userName"
              outlined
              label="Username"
              placeholder="username"
              hide-details
              class="mb-3"
              :rules="[v => v.length >= 3]"
            ></v-text-field>

            <v-text-field
              v-model="userEmail"
              outlined
              label="Email"
              placeholder="Email"
              hide-details
              class="mb-3"
              :rules="[v => v.length >= 3]"
            ></v-text-field>


            <v-btn block color="primary" class="mt-6" @click="forgetPassword">
              Send Email
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <!-- tree -->
    <v-img class="auth-tree" width="247" height="185" src="@/assets/images/misc/tree.png"></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions(["forgotPassword", "snackAlert"]),

    forgetPassword() {
      if (this.$refs.form.validate()) {
        let params = {
          email: this.userEmail,
          userName: this.userName
        };
        this.forgotPassword({
          params
        }).then(result => {
          this.snackAlert({
              message: "Successfully sent reset password email",
              type: "success"
            });
        });
      }
    }
  },
  setup() {
    const loginUser = localStorage && localStorage.getItem("loginUser") ? JSON.parse(localStorage.getItem("loginUser")) : { userName: "" };
    const userName = loginUser.userName;
    return {
      userName,
      userEmail: '',
      valid: true,
    };
  }
};
</script>

<style lang="scss">
@import "~@/plugins/vuetify/default-preset/preset/pages/auth.scss";
</style>
