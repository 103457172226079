<template>
  <v-row>
    <v-col>
      <v-col cols="12" md="12">
        <auto-pilot @apIntervalEnd="apIntervalEnd"></auto-pilot>
      </v-col>
      <v-col cols="12" md="12">
        <search-reference-number></search-reference-number>
      </v-col>
    </v-col>
    <v-col cols="12" md="6">
      <Dashboard-Statistics-Card></Dashboard-Statistics-Card>
    </v-col>
    <receipt-dialogue :show-dialogue="getShowDialogue()" @close="showDialogue = false" v-if="showDialogue" />
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

// demos
import AutoPilot from "./AutoPilot.vue";
import DashboardStatisticsCard from "./DashboardStatisticsCard.vue";
import ReceiptDialogue from "./ReceiptDialogue.vue";
import SearchReferenceNumber from "@/components/SearchReferenceNumber.vue";

export default {
  computed: {
    ...mapGetters(["showDialogue", "userRole"])
  },
  components: {
    AutoPilot,
    DashboardStatisticsCard,
    ReceiptDialogue,
    SearchReferenceNumber,
  },

  data() {
    return {};
  },
  methods: {
    ...mapActions(["getOneReceipt"]),
    async apIntervalEnd() {
      if (this.showDialogue) return;
      try {
        const res = await this.getOneReceipt()
        if (res) {
          this.$store.commit("setShowDialogue", true);
        }
      } catch (error) {
        console.log("error getOneReceipt:: ", error);
      }
    },
    getShowDialogue () {
      return this.showDialogue;
    }
  },
};
</script>
