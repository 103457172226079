<template>
  <v-card id="account-setting-card">
    <!-- tabs -->
    <v-tabs v-model="tab" show-arrows>
      <v-tab v-for="tab in tabs" :key="tab.icon">
        <v-icon size="20" class="me-3">
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <account-settings-account
          :account-data="accountSettingData.account"
        ></account-settings-account>
      </v-tab-item>

      <v-tab-item>
        <account-settings-security></account-settings-security>
      </v-tab-item>

      <v-tab-item>
        <account-settings-info
          :information-data="accountSettingData.information"
        ></account-settings-info>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { mdiAccountOutline, mdiLockOpenOutline, mdiInformationOutline } from "@mdi/js";
import { ref } from "vue"

// demos
import AccountSettingsAccount from "./AccountSettingsAccount.vue";
import AccountSettingsSecurity from "./AccountSettingsSecurity.vue";
import AccountSettingsInfo from "./AccountSettingsInfo.vue";

export default {
  components: {
    AccountSettingsAccount,
    AccountSettingsSecurity,
    AccountSettingsInfo
  },
  setup() {
    const tab = ref("");

    // tabs
    const tabs = [
      { title: "Account", icon: mdiAccountOutline },
      { title: "Security", icon: mdiLockOpenOutline },
      { title: "Info", icon: mdiInformationOutline }
    ];

    // account settings data
    const userInfo = JSON.parse(localStorage.getItem("user"))
    const accountSettingData = {
      account: {
        avatarImg: require("@/assets/images/avatars/1.png"),
        username: userInfo.userName,
        role: userInfo.role
      },
      information: {
        bio: "Bio",
        birthday: "February 22, 1995",
        phone: "954-006-0844",
        website: "https://themeselection.com/",
        country: "USA",
        languages: ["English", "Spanish"],
        gender: "male"
      }
    };

    return {
      tab,
      tabs,
      accountSettingData,
      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline
      }
    };
  }
};
</script>
