<template>
  <div>
    <v-select
      md="4"
      required
      outlined
      :items="items"
      label="Country"
      item-text="text"
      item-value="value"
      v-model="currentReceipt.country"
      :prepend-icon="icons.mdiMapMarker"
      :rules="[v => !!v || 'Please select a country']"
      :disabled="disabled"
      dense
    ></v-select>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mdiMapMarker } from "@mdi/js";
export default {
  data() {
    return {
      countryMarket: {
        'tr': [{
          text: "Turkey",
          value: "tr"
        }],
        'de': [{
          text: "Germany",
          value: "de"
        },
        {
          text: "Austria",
          value: "at"
        },
        {
          text: "Switzerland",
          value: "ch"
        }],
        'gr': [{
          text: "Greece",
          value: "gr"
        }],
        'ro': [{
          text: "Romania",
          value: "ro"
        }],
        'hu': [{
          text: "Hungary",
          value: "hu"
        }],
        'pl': [{
          text: "Poland",
          value: "pl"
        }],
        'cz': [{
          text: "Czech",
          value: "cz"
        }],
        'sk': [{
          text: "Slovakia",
          value: "sk"
        }],
        'coketest': [{
          text: "Coke Test",
          value: "cokeTest"
        }],
      },
      icons: {
        mdiMapMarker
      }
    };
  },
  mounted () {
    if (this.items.length == 1) {
      this.currentReceipt.country = this.items[0].value;
    }
  },
  props: {
    disabled: Boolean
  },
  computed: {
    ...mapGetters(["currentReceipt", "market"]),
    items: {
      get() {
        return this.countryMarket[this.market];
      },
      set(value) {
        this.$store.state.currentReceipt.country = value;
      }
    }
  }
};
</script>
