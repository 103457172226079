export default {
  snackBar: state => state.snackBar,
  showSnackBar: state => state.showSnackBar,
  isAuthenticated: state => state.isAuthenticated,
  userToken: state => state.userToken,
  userRole: state => state.userRole,
  userName: state => state.userName,
  market: state => state.market,
  darkTheme: state => state.darkTheme,
  autoPilotMode: state => state.autoPilotMode,
  currentReceipt: state => state.currentReceipt,
  showDialogue: state => state.showDialogue,
  prevPartcipations: state => state.prevPartcipations,
  processHistory: state => state.processHistory,
  paymentHistory: state => state.currentReceipt.paymentHistory,
  deviceInfo: state => state.deviceInfo,
  routes: state => state.routes,
  globalCampaignId: state => state.globalCampaignId,
  campaignNameMap: state => state.campaignNameMap,
  getCampaignById: (state) => (id) => {
    return state.campaigns.find(campaign => campaign.campaignId == id);
  }
};
