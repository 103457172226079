<template>
  <div v-if="currentReceipt.receiptImageURL" class="viewer image-container">
    <v-container>
      <v-flex>
      </v-flex>
      <v-row class="topButtons">
        <v-col :cols="!hasProductImageUrls ? '12' : '6'" class="m-0">
          <v-btn
            tile
            depressed
            width="100%"
            :color="showReceiptImage ? 'secondary' : ''"
            @click="setShowReceiptImage(true, '')"
          >
            Receipt
          </v-btn>
        </v-col>
        <v-col v-if="hasProductImageUrls" cols="6">
          <v-btn
            width="100%"
            tile
            depressed
            :color="!showReceiptImage ? 'secondary' : ''"
            @click="setShowReceiptImage(false)"
          >
            Product
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-flex md12 class="image-container">
          <div
            v-if="showReceiptImage"
            class="images"
            v-viewer="{ inline: true, button: true, fullscreen: false, navbar: false }"
          >
            <img style="width: 100%;" :src="currentReceipt.receiptImageURL" alt="Receipt image">
          </div>
          <ProductImageCarousel v-else />
        </v-flex>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import 'viewerjs/dist/viewer.css'
import ProductImageCarousel from "./ProductImageCarousel.vue";
export default {
  data: () => {
    return {
      showReceiptImage: true,
      showProductImage: false,
    }
  },
  components: {
    ProductImageCarousel
  },
  props: {
  },
  methods: {
    inited(viewer) {
      this.$viewer = viewer
    },
    show() {
      this.$viewer.show()
    },
    setShowReceiptImage(status) {
      this.showReceiptImage = status;
      this.showProductImage = !status;
    },
  },
  computed: {
    ...mapGetters(["currentReceipt"]),
    imgBtnColor() {
      return this.$vuetify.theme.isDark ? {
        selected: 'grey darken-4',
        unselected: 'grey darken-3'
      } : {
        selected: 'grey lighten-1',
        unselected: 'grey lighten-3'
      };
    },
    productImageUrls() {
      const productImageUrls = this.currentReceipt.productImageURL ? this.currentReceipt.productImageURL : []
      let extra = []
      if (this.currentReceipt.extra && typeof this.currentReceipt.extra == 'string')
        extra = JSON.parse(this.currentReceipt.extra)
      else if (this.currentReceipt.extra)
        extra = this.currentReceipt.extra
      // const extra = this.currentReceipt.extra ? JSON.parse(this.currentReceipt.extra) : []
      const agentUploadedImages = extra.productImageUploadArray ? extra.productImageUploadArray : []

      const total = [...productImageUrls, ...agentUploadedImages]
      return total;
    },
    hasProductImageUrls() {
      return this.productImageUrls && this.productImageUrls.length ? true : false
    }
  }
};
</script>
<style lang="scss" scoped>
.image-container {
  height: 100%;
}

.viewer.image-container {
  overflow: scroll;
}

.topButtons {
  z-index: 12;
  padding: 0 !important;

  .col-6,
  .col-12 {
    padding: 0 !important;
  }
}
</style>
