module.exports = {
  tr: {
    walletCampaign: true,
    defaultCampaignId: '3219',
    login: '/login',
    statistics: '/dashboarderStatistics',
    getOneReceipt: '/getOneReceipt',
    getReferenceNumber: '/getReferenceNumber',
    getDashboardData: '/dashboardData',
    reEvaluateReceipt: '/reEvaluateCb123',
    dropReceipt: '/dropReceipt',
    getOneReceiptServiceDash: '/getOneReceiptMoneyback',
    getReferenceNumberServiceDash: '/getReferenceNumberMoneyback',
  },
  de: {
    defaultCampaignId: '1886',
    defaultServiceDashCampaignId: 'joltx-de-moneyBack',
    login: '/login',
    statistics: '/dashboarderStatistics',
    getOneReceipt: '/getOneReceipt',
    getReferenceNumber: '/getReferenceNumber',
    getDashboardData: '/dashboardData',
    getDashboardDataService: '/dashboardDataService',
    reEvaluateReceipt: '/reEvaluateCb123',
    dropReceipt: '/dropReceipt',
    getOneReceiptServiceDash: '/getOneReceiptMoneyback',
    getReferenceNumberServiceDash: '/getReferenceNumberMoneyback',
  },
  gr: {
    defaultCampaignId: '1788',
    login: '/login',
    statistics: '/dashboarderStatistics',
    getOneReceipt: '/getOneReceipt',
    getReferenceNumber: '/getReferenceNumber',
    getDashboardData: '/dashboardData',
    reEvaluateReceipt: '/reEvaluateCb123',
    dropReceipt: '/dropReceipt',
    getOneReceiptServiceDash: '/getOneReceiptMoneyback',
    getReferenceNumberServiceDash: '/getReferenceNumberMoneyback',
  },
  gr_wallet: {
    defaultCampaignId: '30401',
    login: '/login',
    statistics: '/dashboarderStatistics',
    getOneReceipt: '/getOneReceipt',
    getReferenceNumber: '/getReferenceNumber',
    getDashboardData: '/dashboardData',
    reEvaluateReceipt: '/reEvaluateCb123',
    dropReceipt: '/dropReceipt',
    getOneReceiptServiceDash: '/getOneReceiptMoneyback',
    getReferenceNumberServiceDash: '/getReferenceNumberMoneyback',
  },
  ro: {
    defaultCampaignId: '30319',
    login: '/login',
    statistics: '/dashboarderStatistics',
    getOneReceipt: '/getOneReceipt',
    getReferenceNumber: '/getReferenceNumber',
    getDashboardData: '/dashboardData',
    reEvaluateReceipt: '/reEvaluateCb123',
    dropReceipt: '/dropReceipt',
    getOneReceiptServiceDash: '/getOneReceiptMoneyback',
  },
  ro_wallet: {
    defaultCampaignId: '30030',
    login: '/login',
    statistics: '/dashboarderStatistics',
    getOneReceipt: '/getOneReceipt',
    getReferenceNumber: '/getReferenceNumber',
    getDashboardData: '/dashboardData',
    reEvaluateReceipt: '/reEvaluateCb123',
    dropReceipt: '/dropReceipt',
  },
  hu: {
    defaultCampaignId: '30072',
    defaultServiceDashCampaignId: 'joltx-hu-highValueCashback-wallet',
    login: '/login',
    statistics: '/dashboarderStatistics',
    getOneReceipt: '/getOneReceipt',
    getReferenceNumber: '/getReferenceNumber',
    getDashboardData: '/dashboardData',
    reEvaluateReceipt: '/reEvaluateCb123',
    dropReceipt: '/dropReceipt',
    getOneReceiptServiceDash: '/getOneReceiptMoneyback',
    getReferenceNumberServiceDash: '/getReferenceNumberMoneyback',
  },
  hu_wallet: {
    defaultCampaignId: '30057',
    defaultServiceDashCampaignId: 'joltx-hu-highValueCashback-wallet',
    login: '/login',
    statistics: '/dashboarderStatistics',
    getOneReceipt: '/getOneReceipt',
    getReferenceNumber: '/getReferenceNumber',
    getDashboardData: '/dashboardData',
    reEvaluateReceipt: '/reEvaluateCb123',
    dropReceipt: '/dropReceipt',
    getOneReceiptServiceDash: '/getOneReceiptMoneyback',
    getReferenceNumberServiceDash: '/getReferenceNumberMoneyback',
  },
  pl: {
    defaultCampaignId: '30425',
    defaultServiceDashCampaignId: '30151',
    login: '/login',
    statistics: '/dashboarderStatistics',
    getOneReceipt: '/getOneReceipt',
    getReferenceNumber: '/getReferenceNumber',
    getDashboardData: '/dashboardData',
    reEvaluateReceipt: '/reEvaluateCb123',
    dropReceipt: '/dropReceipt',
    getOneReceiptServiceDash: '/getOneReceiptMoneyback',
    getReferenceNumberServiceDash: '/getReferenceNumberMoneyback',
  },
  cz: {
    defaultCampaignId: '30103',
    defaultServiceDashCampaignId: 'joltx-cz-highValueCashback',
    login: '/login',
    statistics: '/dashboarderStatistics',
    getOneReceipt: '/getOneReceipt',
    getReferenceNumber: '/getReferenceNumber',
    getDashboardData: '/dashboardData',
    reEvaluateReceipt: '/reEvaluateCb123',
    dropReceipt: '/dropReceipt',
    getOneReceiptServiceDash: '/getOneReceiptMoneyback',
    getReferenceNumberServiceDash: '/getReferenceNumberMoneyback',
  },
  sk: {
    defaultCampaignId: '30104',
    defaultServiceDashCampaignId: 'joltx-sk-highValueCashback',
    login: '/login',
    statistics: '/dashboarderStatistics',
    getOneReceipt: '/getOneReceipt',
    getReferenceNumber: '/getReferenceNumber',
    getDashboardData: '/dashboardData',
    reEvaluateReceipt: '/reEvaluateCb123',
    dropReceipt: '/dropReceipt',
    getOneReceiptServiceDash: '/getOneReceiptMoneyback',
    getReferenceNumberServiceDash: '/getReferenceNumberMoneyback',
  },
  coketest: {
    defaultCampaignId: 'coketest',
    login: '/login',
    statistics: '/dashboarderStatistics',
    getOneReceipt: '/getOneReceipt',
    getReferenceNumber: '/getReferenceNumber',
    getDashboardData: '/dashboardData',
    reEvaluateReceipt: '/reEvaluateCb123',
    dropReceipt: '/dropReceipt',
    getOneReceiptServiceDash: '/getOneReceiptMoneyback',
    getReferenceNumberServiceDash: '/getReferenceNumberMoneyback',
  },
}
