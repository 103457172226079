<template>
  <div>
    <v-card>
      <v-card-title class="primary"
        ><span class="white--text">Search Consumer ID/Email</span></v-card-title
      >
      <v-card-actions>
        <v-text-field
          clearable
          v-model="consumerInfo"
          label="Consumer ID/Email"
          :prepend-icon="icons.mdiAccount"
          type="text"
          color="black"
          @keypress.enter="searchConsumer"
        ></v-text-field>
      </v-card-actions>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn @click="searchConsumer" color="primary" large>Search</v-btn>
      </v-card-actions>
    </v-card>

    <v-card class="mt-4" v-if="receipts.length > 0">
      <v-simple-table dense>
        <thead>
          <tr>
            <th>Reference Number</th>
            <th>Created At</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody v-for="item of receipts" :key="item.referenceNumber">
          <tr class="cursor" @click="getReceipt({ referenceNumber: item.referenceNumber })">
            <td>{{ item.referenceNumber }}</td>
            <td>{{ getDateTime(item.createdAt) }}</td>
            <td>{{ item.status }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>
import { mdiAccount } from "@mdi/js";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      receipts: [],
      consumerInfo: "",
      icons: {
        mdiAccount,
      },
    };
  },
  methods: {
    ...mapActions(["getReceiptsWithConsumerInfo", "snackAlert", "getReceiptWithReferenceNumber"]),
    searchConsumer() {
      if (this.consumerInfo) {
        const params = this.consumerInfo.includes("@")
          ? { email: this.consumerInfo }
          : { consumerId: this.consumerInfo };
        this.getReceiptsWithConsumerInfo(params).then((res) => {
          if (res) {
            this.receipts = res.receipts;
          } else {
            this.snackAlert({
              message: `No receipts found`,
            });
          }
        });
      } else {
        this.snackAlert({
          message: `Wrong consumer ID/Email`,
        });
      }
    },
    getDateTime(date) {
      return this.$moment.parseZone(date).format("MMMM Do YYYY, HH:mm:ss");
    },
    async getReceipt({referenceNumber}) {
      try {
        const res = await this.getReceiptWithReferenceNumber({referenceNumber})
        if (res) this.$store.commit("setShowDialogue", true);
      } catch (error) {
        console.log(error)
      }
    },
  },
};
</script>
