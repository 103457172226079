<template>
  <v-container>
    <v-row align="center">
      <v-col class="d-flex justify-space-between align-center">
        <span>{{ selectedReceipts.length }} selected</span>
        <v-btn color="primary" @click="moveToPreConfirmed">Move Pre-Confirmed</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-select
          v-model="selectedCampaignIds"
          :items="campaignIds"
          label="Select Campaign IDs"
          single-line
          multiple
          clearable
          @change="applyFilters"
        ></v-select>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="referenceNumberFilter"
          label="Filter by Reference Number"
          single-line
          clearable
          @input="debouncedFilter"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="filteredData"
      :loading="loading"
      :items-per-page="5"
      :page.sync="currentPage"
      @page-count="totalPages = $event"
      show-select
      v-model="selectedReceipts"
      item-key="referenceNumber"
      class="elevation-1"
    ></v-data-table>
  </v-container>
</template>


<script>
import { mapActions, mapGetters } from "vuex";
import debounce from "lodash/debounce";

export default {
  data() {
    return {
      selectedCampaignIds: [],
      referenceNumberFilter: '',
      allData: [],
      filteredData: [],
      campaignIds: [],
      selectedReceipts: [],
      loading: false,
      headers: [
        { text: 'Campaign ID', value: 'campaignId' },
        { text: 'Reference Number', value: 'referenceNumber' },
        { text: 'Status', value: 'status' }
      ],
      currentPage: 1,
      totalPages: 0,
    };
  },
  created() {
    this.debouncedFilter = debounce(this.applyFilters, 300);
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    ...mapActions(["getHoldOnReceipts", "moveHoldOnToPreConfirmed"]),
    async fetchData() {
      this.loading = true;

      try {
        const data = await this.getHoldOnReceipts()
        this.allData = data
        this.campaignIds = [...new Set(data.map(item => item.campaignId))]; // Extract unique campaignIds
        this.applyFilters();

      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error appropriately in your UI
      } finally {
        this.loading = false;
      }

    },
    applyFilters() {
      let data = this.allData;

      if (this.selectedCampaignIds.length) {
        data = data.filter(item => this.selectedCampaignIds.includes(item.campaignId));
      }

      if (this.referenceNumberFilter) {
        data = data.filter(item => item.referenceNumber.includes(this.referenceNumberFilter));
      }

      this.filteredData = data;
      this.currentPage = 1; // Reset to first page after filtering
      this.selectedReceipts = []; // Clear selected receipts whenever filters are applied

    },
    async moveToPreConfirmed() {
      // Perform API call with selectedReceipts
      const receiptTokensToUpdate = this.selectedReceipts.map(item => item.receiptToken)
      await this.moveHoldOnToPreConfirmed({
        receiptTokensToUpdate
      })

      this.refreshData()
    },
    refreshData() {
      // Clear all selections and refetch data
      this.selectedCampaignIds = [];
      this.referenceNumberFilter = '';
      this.selectedReceipts = [];
      this.fetchData();
    }
  },
};
</script>
