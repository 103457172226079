<template>
  <v-row>
    <v-col cols="8">
      <date-picker
        :dateRule="true"
        label="Receipt Date"
        :icon="icons.mdiCalendarQuestion"
        :value="receiptDateTime.formattedDate"
        @input="receiptDateTime = { type: 'date', value: $event }"
        :min="computedStartDate"
        :max="computedEndDate"
        format="DD/MM/YYYY"
        :required="true"
        writable
        :rules="[v => !!v || 'Please enter the receipt date']"
        :disabled="disabled"
      />
    </v-col>
    <v-col cols="4">
      <v-text-field
        outlined
        :value="receiptDateTime.formattedTime"
        :prepend-icon="icons.mdiClockOutline"
        @input="receiptDateTime = { type: 'time', value: $event }"
        label="Receipt Time"
        format="24hr"
        type="time"
        :required="true"
        :rules="[v => !!v || 'Please enter the receipt time']"
        :disabled="disabled"
        dense
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import DatePicker from "@/components/Dialogue/DatePicker";
import { mdiCalendarQuestion, mdiClockOutline } from "@mdi/js";

export default {
  data() {
    return {
      icons: {
        mdiCalendarQuestion,
        mdiClockOutline
      }
    };
  },
  methods: {
    getToday() {
      return this.$moment().format("YYYY-MM-DD");
    }
  },
  components: {
    DatePicker
  },
  props: {
    disabled: Boolean
  },
  computed: {
    ...mapGetters(["currentReceipt", "userRole"]),
    campaign() {
      return this.$store.getters.getCampaignById(this.currentReceipt.campaignId);
    },
    computedStartDate () {
      // if (this.userRole === "AGENT")
      //   return "2000-01-01"
      const campaign = this.campaign;
      if (campaign && campaign.startDate <= this.getToday())
        return campaign.startDate
      return "2022-01-01"
    },
    computedEndDate () {
      const campaign = this.campaign;
      // if (this.userRole === "AGENT")
      //   return this.getToday()
      if (this.getToday () > campaign.endDate)
        return campaign.endDate
      return this.getToday()
    },
    receiptDateTime: {
      get() {
        const receiptDateTime = this.currentReceipt.receiptDateTime;
        if (!receiptDateTime)
          return {
            formattedDate: null,
            formattedTime: null
          };
        const formattedTime = this.$moment.parseZone(receiptDateTime).format("HH:mm");

        this.currentReceipt.receiptDate = this.$moment.parseZone(receiptDateTime).format("YYYY-MM-DD");
        this.currentReceipt.receiptTime = formattedTime;
        return {
          formattedDate: receiptDateTime,
          formattedTime
        };
      },
      set(val) {
        const { formattedDate: receiptDateTime, formattedTime } = this.receiptDateTime;
        const formattedDate = this.$moment.parseZone(receiptDateTime).format("YYYY-MM-DD");
        const { type, value } = val;
        let newDate = null;
        if (value) {
          if (type === "date") {
            newDate = this.$moment.parseZone(`${value} ${formattedTime || "00:00"}`).format("YYYY-MM-DD HH:mm");
          } else if (type === "time") {
            newDate = this.$moment.parseZone(`${formattedDate} ${value}`).format("YYYY-MM-DD HH:mm");
          }
          this.currentReceipt.receiptDateTime = newDate;
          this.currentReceipt.receiptDate = formattedDate;
          this.currentReceipt.receiptTime = formattedTime;
        }
      }
    }
  }
};
</script>
