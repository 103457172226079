<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <div class="image_block">
        <img :src="require(`@/assets/images/misc/js-logo.png`)" alt="Justsnap" />
      </div>
      <v-card class="auth-card">
        <v-card-text>
          <v-form ref="form" v-model="valid" @keyup.native.enter="loginUser">
            <v-text-field
              v-model="newPassword"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
              :rules="[
                (v) => !!v || 'Login.passwordRequired',
                (v) => v.length >= 6 || 'Login.passwordMoreThen',
                (v) => v.length <= 16 || 'Login.passwordLessThen',
              ]"
            ></v-text-field>

            <v-text-field
              v-model="newPasswordConfirm"
              outlined
              class="mt-3"
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password Confirm"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
              :rules="[
                (v) => !!v || 'Login.passwordRequired',
                (v) => v.length >= 6 || 'Login.passwordMoreThen',
                (v) => v.length <= 16 || 'Login.passwordLessThen',
              ]"
            ></v-text-field>

            <v-btn block color="primary" class="mt-6" @click="resetUserPassword">
              Reset Password
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <!-- tree -->
    <v-img class="auth-tree" width="247" height="185" src="@/assets/images/misc/tree.png"></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { mdiEyeOutline, mdiEyeOffOutline } from "@mdi/js";

export default {
  methods: {
    ...mapActions(["resetPassword", "snackAlert"]),

    resetUserPassword() {
      if (this.newPassword !== this.newPasswordConfirm) {
        this.snackAlert("Passwords do not match");
        return;
      }

      if (this.$refs.form.validate()) {
        let params = {
          password: this.newPassword,
          token: this.$route.query.token,
        };
        this.resetPassword({
          params,
        }).then((result) => {
          this.snackAlert({
            message: "Successfully",
            type: "success",
          });
          this.$router.push({
              name: "login",
            });
        });
      }
    },
  },
  setup() {
    return {
      isPasswordVisible: false,
      newPassword: "",
      newPasswordConfirm: "",
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    };
  },
};
</script>

<style lang="scss">
@import "~@/plugins/vuetify/default-preset/preset/pages/auth.scss";
</style>
