<template>
  <v-card>
    <v-card-title class="primary">
      <span class="white--text">Receipt By Reference Number</span>
    </v-card-title>
    <v-card-actions>
      <v-text-field
        clearable
        v-model="referenceNumber"
        label="Receipt Reference Number"
        :prepend-icon="icons.mdiBarcode"
        type="text"
        color="black"
        counter="10"
        @keypress.enter="searchReferenceNumber"
      ></v-text-field>
    </v-card-actions>
    <v-card-actions>
      <div class="flex-grow-1"></div>
      <v-btn @click="searchReferenceNumber" color="primary" large>
        Search
      </v-btn>
      <v-btn v-if="market == 'gr' && userRole == 'VIEWER'" @click="searchReceiptId" color="info" large>
        Search By Receipt ID
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mdiBarcode } from "@mdi/js";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    const user = JSON.parse(localStorage.getItem("user"));
    const userRole = user.role;

    return {
      referenceNumber: '',
      icons: {
        mdiBarcode
      },
      userRole
    };
  },
  props: {
    serviceDash: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["market"]),
  },
  methods: {
    ...mapActions(["getReceiptWithReferenceNumber", "getReceiptWithReceiptId", "snackAlert"]),
    async searchReferenceNumber() {
      if (this.referenceNumber && this.referenceNumber.length === 10) {
        try {
          const res = await this.getReceiptWithReferenceNumber({ referenceNumber: this.referenceNumber, refEndpoint: this.serviceDash ? 'getReferenceNumberServiceDash' : null, serviceDash: this.serviceDash ? 'defaultServiceDashCampaignId' : null })
          if (res) this.$store.commit("setShowDialogue", true);
        } catch (error) {
          console.log(error)
        }
      } else {
        this.snackAlert({
          message: `Wrong reference number`
        });
      }
    },
    searchReceiptId() {
      if (this.referenceNumber) {

        this.getReceiptWithReceiptId({ receiptId: this.referenceNumber, market: this.market }).then(hasReceipt => {
          if (hasReceipt) this.$store.commit("setShowDialogue", true);
        });
      } else {
        this.snackAlert({
          message: `Wrong Receipt ID`
        });
      }
    },
  }
};
</script>
