import vue from "vue";

export default {
  snackBar: (state, payload) => {
    state.snackBar = payload;
    state.showSnackBar = true;
  },
  setSnackBarShow: (state, value) => {
    state.showSnackBar = value;
  },
  setIsAuthenticated: (state, value) => {
    state.isAuthenticated = value;
  },
  setUserToken: (state, value) => {
    state.userToken = value;
  },
  setUserRole: (state, value) => {
    state.userRole = value;
  },
  setUserName: (state, value) => {
    state.userName = value;
  },
  setMarket: (state, value) => {
    state.market = value;
  },
  setDarkTheme: (state, value) => {
    state.darkTheme = value;
  },
  setAutoPilotMode: (state, value) => {
    state.autoPilotMode = value;
  },
  setCurrentReceipt: (state, value) => {
    state.currentReceipt = value;
  },
  setShowDialogue: (state, value) => {
    state.showDialogue = value;
  },
  setPrevPartcipations: (state, value) => {
    state.prevPartcipations = value;
  },
  setProcessHistory: (state, value) => {
    value = typeof value === "string" ? JSON.parse(value) : value;
    state.processHistory = value;
  },
  setPaymentHistory: (state, value) => {
    state.paymentHistory = value;
  },
  setDeviceInfo: (state, value) => {
    state.deviceInfo = value;
  },
  updateGlobalCampaignId: (state, value) => {
    state.globalCampaignId = value;
  },
  setCampaignsData(state, payload) {
    state.campaigns = payload;
  },
  setCampaignNames(state, payload){
    state.campaignNameMap = payload
  }
};
