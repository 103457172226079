<template>
  <v-dialog
    :value="showDialogue"
    transition="dialog-bottom-transition"
    @click:outside="hideViewer()"
    @keydown.esc="hideViewer()"
    min-width="600px"
    content-class="overflow-hidden"
  >
    <v-main style="max-height: 88vh; padding: 0 !important" class="pa-0">
      <v-container
        fluid
        class="p-5 d-flex flex-column flex-grow-1 fill-parent-height"
        :style="darkTheme === 'true' ? 'background-color: #2C2843' : 'background-color: #e9e1f0'"
      >
        <v-row class="pa-5 pb-2 mb-4 title" justify-lg="start" style="column-gap: 20px;">
          <div>
            <v-chip :color="getColor" class="rounded-pill mb-3">
              {{ receiptStatus(currentReceipt) }}
            </v-chip>
            <div v-if="extraText" class="extra-text" @click="copyErroringRef">
              <v-tooltip :disabled="tooltipText == ''" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip v-bind="attrs" v-on="on" class="rounded">
                    {{ extraText }}
                  </v-chip>
                </template>
                <div v-if="tooltipText">
                  {{ tooltipText }}
                </div>
              </v-tooltip>
            </div>
          </div>
          <div>
            Campaign ID:
            <v-chip class="rounded">
              {{ currentReceipt.campaignId }}
            </v-chip>
          </div>
          <div>
            Campaign Name:
            <v-chip class="rounded">
              {{ getCampaignName }}
            </v-chip>
          </div>
          <div>
            Reference:
            <span class="clipboard" @click="copy(currentReceipt.referenceNumber)">{{
              currentReceipt.referenceNumber
            }}</span>
          </div>

          <!-- Agent Only Header -->
          <div v-if="userRole === 'AGENT' || userRole === 'VIEWER'" class="msgcont">
            <v-chip color="info" class="msg">
              <span>
                {{ currentReceipt.paymentStatus }}
              </span>
            </v-chip>
          </div>
          <div v-if="userRole === 'AGENT'" class="msgcont">
            <v-chip color="grey" class="msg">
              <span class="black--text">
                MB Unique Code: {{ getUniqueCode }}
              </span>
            </v-chip>
            <v-chip :color="currentReceipt.sendSftp === 1 ? 'blue' : 'grey'" class="msg">
              <span class="black--text">
                {{ currentReceipt.sendSftp === 1 ? "Stfp Sent" : "Stfp Unsent" }}
              </span>
            </v-chip>
          </div>
          <div class="msgcont">
            <v-chip class="msg">
              <span class="black--text">
                Receipt Created At: {{ getDateTime(currentReceipt.createdAt) }}
              </span>
            </v-chip>
          </div>
          <div class="msgcont">
            <v-chip class="msg">
              <span class="black--text">
                Payment Amount: {{ currentReceipt.paymentAmount ? currentReceipt.paymentAmount : 'N/A' }}
              </span>
            </v-chip>
          </div>
        </v-row>
        <v-row no-gutters class="top-row flex-grow-1 flex-shrink-1 ma-0">
          <v-col cols="6" class="fill-parent-height">
            <receipt-viewer />
          </v-col>
          <v-col cols="6" class="fill-parent-height">
            <receipt-details @close="hideViewer()" :service-dash="serviceDash" />
          </v-col>
        </v-row>
        <v-row>
          <v-dialog
            persistent
            @click:outside="hideViewer()"
            @keydown.esc="hideViewer()"
            min-width="400px"
            content-class="overflow-hidden"
            class="p-5"
          >
          </v-dialog>
        </v-row>
      </v-container>
    </v-main>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ReceiptViewer from "@/components/Dialogue/ReceiptViewer.vue";
import ReceiptDetails from "@/components/Dialogue/ReceiptDetails.vue";
export default {
  components: {
    ReceiptViewer,
    ReceiptDetails
  },
  props: {
    showDialogue: {
      required: true,
      type: Boolean
    },
    serviceDash: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      darkTheme: localStorage.darkTheme,
      extraText: '',
      tooltipText: ''
    };
  },
  computed: {
    ...mapGetters(["rejectReasons", "currentReceipt", "campaignNameMap"]),
    ...mapGetters(["userRole"]),

    getColor() {
      const status = this.currentReceipt.status;
      if (status === "Confirmed") return "success";
      else if (status === "Rejected") return "error";
      else if (status === "Pre-Confirmed") return "secondary";
      else if (status === "Pre-Evaluated") return "info";
      else return "blue-grey";
    },
    getCampaignName () {
      const micsDataObj = this.currentReceipt.extra ? JSON.parse(this.currentReceipt.extra) : {}
      let campaignName = this.campaignNameMap[this.currentReceipt.campaignId] || 'N/A'
      if(campaignName) return campaignName
      else return micsDataObj['micsData'].campaignName ? micsDataObj['micsData'].campaignName : 'N/A'
    },
    getUniqueCode () {
      const extra = this.currentReceipt.extra ? JSON.parse(this.currentReceipt.extra) : {}
      return extra.uniqueCode ? extra.uniqueCode : 'N/A'
    }
  },
  methods: {
    ...mapActions(["dropReceipt", "snackAlert"]),
    hideViewer() {
      this.dropReceipt({campaignId: this.currentReceipt.campaignId, receiptToken: this.currentReceipt.receiptToken});
      this.$store.commit("setCurrentReceipt", {});
      this.$store.commit("setPaymentHistory", []);
      this.$store.commit("setPrevPartcipations", []);
      this.$store.commit("setShowDialogue", false);
    },
    copy(text) {
      /* Copy the text inside the text field */
      navigator.clipboard.writeText(text);

      /* Alert the copied text */
      this.snackAlert({
        message: "Reference number copied to clipboard",
        type: "success"
      });
    },
    receiptStatus(receipt) {
      if (receipt.status == 'Rejected') {
        this.extraText = receipt.rejectReason
        if (receipt.rejectReason == 'used') this.tooltipText = receipt.erroringRefNumbers ? receipt.erroringRefNumbers : ''
        return `REJECTED`
      } else if (receipt.status == 'Pre-Evaluated') {
        if (receipt.reClaimText) this.extraText = `${receipt.reClaimReason} - ${receipt.reClaimText}`
        else this.extraText = receipt.reClaimReason
        return `PRE-EVALUATED`
      } else if (receipt.status == 'Confirmed') {
        return `CONFIRMED - ${receipt.paymentAmount}`
      } else {
        return receipt.status
      }
    },
    getDateTime(date) {
      return this.$moment.parseZone(date).format("DD/MM/YYYY, HH:mm:ss");
    },
    copyErroringRef () {
      if (this.currentReceipt.rejectReason == 'used' && this.currentReceipt.erroringRefNumbers) {
        navigator.clipboard.writeText(this.currentReceipt.erroringRefNumbers);
        this.snackAlert({
          message: "Reference number copied to clipboard",
          type: "success"
        });
        return true
      } else return false
    }
  }
};
</script>

<style lang="scss">
.clipboard {
  cursor: pointer;
}
.msgcont {
  margin-left: 2rem;

  .msg + .msg {
    margin-left: 0.5rem;
  }
}

.fill-parent-height {
  height: 100%;
}

.top-row {
  min-height: 0;
}

.topButtons {
  z-index: 12;
}

.extra-text {
  font-size: 14px;
  line-height: 18px;
}
</style>
