<template>
  <v-row>
    <v-col cols="12">
      <v-text-field
        v-if="!!cashbackAmount"
        :prepend-icon="icons.mdiCash"
        label="Cashback Amount"
        v-model="cashbackAmount"
        disabled
        dense
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { mdiCash } from "@mdi/js";
export default {
  data() {
    return {
      icons: {
        mdiCash
      }
    };
  },
  computed: {
    ...mapGetters(["currentReceipt"]),
    cashbackAmount() {
      if (this.currentReceipt.extra) {
        return JSON.parse(this.currentReceipt.extra).cashback ? JSON.parse(this.currentReceipt.extra).cashback : null;
      } else return null
    }
  }
};
</script>
