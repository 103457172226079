<template>
  <v-row>
    <v-row>
      <v-col cols="12" md="12">
        <search-consumer-id v-if="userRole!=='OPERATOR'"></search-consumer-id>
      </v-col>
    </v-row>
    <receipt-dialogue :showDialogue="getShowDialogue" @close="showDialogue = false" v-if="showDialogue" />
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

// demos
import ReceiptDialogue from "./ReceiptDialogue.vue";
import SearchConsumerId from "@/components/SearchConsumerId.vue";


export default {
  computed: {
    ...mapGetters(["showDialogue", "userRole"]),
    getShowDialogue: {
      get() {
        return this.showDialogue;
      }
    }
  },
  components: {
    ReceiptDialogue,
    SearchConsumerId
  },

  data() {
    return {};
  },
}
</script>
