<template>
  <div class="container">
    <v-btn color="primary" class="me-3 mt-5" @click="$refs.refInputEl.click()">
      <span class="d-none d-sm-block">Upload new photo</span>
    </v-btn>

    <input
      ref="refInputEl"
      type="file"
      accept=".jpeg,.png,.jpg"
      :hidden="true"
      @change="uploadImage($event)"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios"

export default {
  name: "agentsUploadFile",
  data () {
    return {
      url: "https://template.justsnap.eu/api/template/uploadAsset"
    }
  },
  methods: {
    ...mapActions(["updateAgentsUploadFile", "snackAlert"]),
    async uploadImage(event) {
      const file = event.target.files ? event.target.files[0] : null;
      if (!file) {
        window.alert("File not found")
        return
      }
      
      const formData = new FormData()
      formData.append('file', file)

      const response = await axios.post(this.url, formData)
      
      if (response.data) {
        const uploadedImageLink = response.data.file
        const data = {
          uploadedImageLink,
          receiptToken: this.currentReceipt.receiptToken,
          campaignId: this.currentReceipt.campaignId
        }
        try {
          await this.updateAgentsUploadFile(data)
          this.snackAlert({ message: "File uploaded successfully", type: "success" })
          this.$emit("close");
        } catch (error) {
          this.snackAlert({ message: "Error uploading file", type: "error" })
        }
      }
    }
  },
  computed: {
    ...mapGetters(["currentReceipt"])
  }
}
</script>


<style lang="scss">

.container {
  display: flex;
  flex-direction: column;
}

.box {
  margin: 10px 0px;
  border: 1px solid gray;
  padding-inline: 1rem;
  border-radius: 16px;
}

.bold {
  font-weight: bold;
}

.center {
  text-align: center;
}

.m-0 {
  margin: 0 !important;
}

</style>
