<template>
  <v-row justify="center">
    <v-col cols="6" md="10" v-if="hideAutoPilot" >
      <auto-pilot @apIntervalEnd="apIntervalEnd"></auto-pilot>
    </v-col>
    <v-col cols="6" md="10">
      <search-reference-number :service-dash="true" ></search-reference-number>
    </v-col>
    <receipt-dialogue :show-dialogue="getShowDialogue" @close="showDialogue = false" v-if="showDialogue" :service-dash="true" />
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import AutoPilot from "./AutoPilot.vue";
import ReceiptDialogue from "./ReceiptDialogue.vue";
import SearchReferenceNumber from "@/components/SearchReferenceNumber.vue";

export default {
  computed: {
    ...mapGetters(["showDialogue", "market"]),
    getShowDialogue: {
      get() {
        return this.showDialogue;
      },
    },

    hideAutoPilot () {
      return this.market != "de"
    }
  },
  components: {
    ReceiptDialogue,
    AutoPilot,
    SearchReferenceNumber,
  },
  methods: {
    ...mapActions(["getOneReceipt"]),
    async apIntervalEnd() {
      if (this.showDialogue) return;
      try {
        const res = await this.getOneReceipt({ refEndpoint: "getOneReceiptServiceDash", serviceDash: 'defaultServiceDashCampaignId' })
        if (res) {
          this.$store.commit("setShowDialogue", true);
        }
      } catch (error) {
        console.log("error getOneReceipt service dash:: ", error);
      }
    },
  },
};
</script>
