<template>
  <v-row>
    <v-col cols="6">
      <v-autocomplete
        outlined
        v-model="currentReceipt.retailer"
        :prepend-icon="icons.mdiStore"
        :items="retailers"
        item-text="name"
        item-value="id"
        label="Select Retailer"
        :required="true"
        :rules="[v => !!v || 'Please select a retailer for the receipt']"
        :disabled="disabled"
        dense
      >
      </v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { mdiStore, mdiShopping } from "@mdi/js";
export default {
  methods: {
    getItemText (item) {
      return `${item.city} - ${item.address}`
    }
  },
  data() {
    const retailers = localStorage.retailers ? JSON.parse(localStorage.retailers) : [];
    return {
      retailers,
      icons: {
        mdiStore,
        mdiShopping
      }
    };
  },
  props: {
    required: Boolean,
    disabled: Boolean
  },
  computed: {
    ...mapGetters(["currentReceipt"])
  }
};
</script>
