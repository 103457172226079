<template>
  <div>
    <div
      v-if="!disabled"
      class="w-100 d-flex justify-space-between"
      style="font-size: 20px; margin: 5px 20px;"
    >
      <div>
        <v-btn
          color="info"
          @click="resetFilter"
        >
        Reset Filter
        </v-btn>
      </div>
      <div>
        <strong>Total: {{ totalAmount }}</strong>
      </div>
    </div>
    <v-data-table
      dense
      :items="products"
      :headers="searchHeaders"
      :item-key="new Date().getMilliseconds().toString()"
      :disabled="disabled"
      :items-per-page="5"
      v-if="!disabled"
    >
      <template #body.prepend>
        <tr>
          <td>
            <v-text-field v-model="brand" type="text" label="Brand"></v-text-field>
          </td>
          <td>
            <v-text-field v-model="ean" type="text" label="EAN"></v-text-field>
          </td>
          <td>
            <v-text-field v-model="name" type="text" label="Product name"></v-text-field>
          </td>
          <td>
            <v-text-field v-model="form" type="text" label="Form"></v-text-field>
          </td>
          <td colspan="1"></td>
        </tr>
      </template>
      <template #[`item.action`]="{ item }">
        <div class="d-flex">
          <v-chip @click="addProduct(item, true)" color="#43A047">
            <v-icon
              small
            >
              {{ icons.mdiPlus }}
            </v-icon>
          </v-chip>
        </div>
      </template>
    </v-data-table>
    <v-divider class="my-5" v-if="!disabled"></v-divider>
    <h3 class="headline my-3">
      Selected Products
    </h3>
    <v-data-table
      :items="campaignSelectedProducts"
      :disable-pagination="true"
      :hide-default-footer="true"
      :headers="headers"
      no-data-text="No Products Selected"
      :item-key="new Date().getMilliseconds().toString()"
      :disabled="disabled"
      dense
    >
      <template #[`item.price`]="{ item }">
        <v-text-field
          required
          @input="changePrice($event, item)"
          v-model="item.price"
          dense
          :disabled="disabled"
        ></v-text-field>
      </template>
      <template #[`item.action`]="{ item }">
        <div class="d-flex">
          <v-icon
            small
            class="mr-2"
            @click="addProduct(item, true)"
            :disabled="disabled"
          >
            {{ icons.mdiPlus }}
          </v-icon>
          <v-icon small @click="removeProduct(item, true)" :disabled="disabled">
            {{ icons.mdiMinus }}
          </v-icon>
        </div>
      </template>
      <template #[`item.rppData`]="{ item }">
        <div>
          <v-autocomplete
            :items="rppItems"
            v-model="item.rpp"
            @change="updateRpp($event, item)"
            dense
            :disabled="disabled"
          >
            <template #selection="{ item }">
              {{ item.item }}
            </template>
            <template #item="{ item }">
              <div style="width: 100%">
                <v-list-item
                  class="d-flex justify-space-between"
                >
                  <div>
                    <span>{{ item.item }}</span>
                    <v-chip color="blue" text-color="black">
                      <span class="black--text">{{ item.price }}</span>
                    </v-chip>
                  </div>
                </v-list-item>
              </div>
            </template>
          </v-autocomplete>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mdiFormatListText, mdiPlus, mdiMinus } from "@mdi/js";

import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["currentReceipt"]),
  },
  data() {
    let products = localStorage[this.productCategory] ? JSON.parse(localStorage[this.productCategory]) : [];
    if (this.productCategory == 'participatingProducts') {
      products = products.filter(p => {
        const receiptCampaignId = Number(this.$store.getters.currentReceipt.campaignId)
        if (p.campaigns && p.campaigns.includes(receiptCampaignId)) {
          return true
        }
      });
    }
    const selectedProducts = this.$store.getters.currentReceipt.products ? this.$store.getters.currentReceipt.products[this.productCategory] : [];

    let rppItems = this.$store.getters.currentReceipt.rppData ? JSON.parse(this.$store.getters.currentReceipt.rppData) : [];
    rppItems.push({ item: "N/A", price: 0 });

    return {
      brand: "",
      ean: "",
      name: "",
      form: "",
      products,
      totalAmount: 0,
      campaignSelectedProducts: selectedProducts || [],
      icons: {
        mdiFormatListText,
        mdiPlus,
        mdiMinus
      },
      headers: [
        {
          text: "Brand",
          align: "left",
          sortable: false,
          value: "brand"
        },
        {
          text: "EAN",
          align: "left",
          sortable: false,
          value: "ean"
        },
        {
          text: "Name",
          align: "left",
          sortable: false,
          value: "name"
        },
        {
          text: "Form",
          align: "left",
          sortable: false,
          value: "form"
        },
        {
          text: "Category",
          align: "left",
          sortable: false,
          value: "category"
        },
        {
          text: "Subsector",
          align: "left",
          sortable: false,
          value: "subSector"
        },
        {
          text: "RPP",
          align: "left",
          sortable: false,
          value: "rppData"
        },
        {
          text: "Count",
          value: "count",
          sortable: false,
        },
        {
          text: "Price",
          align: "left",
          sortable: false,
          value: "price",
          width: "100px"
        },
        {
          text: "Action",
          align: "left",
          sortable: false,
          value: "action"
        },
      ],
      searchHeaders: [
        {
          text: "Brand",
          align: "left",
          sortable: false,
          filter: value => {
            if (!this.brand) return true
            if (!value) return false
            return value.toLowerCase().match(this.brand.toLowerCase())
          },
          value: "brand"
        },
        {
          text: "EAN",
          align: "left",
          sortable: false,
          filter: value => {
            if (!this.ean) return true
            if (!value) return false
            return value.toLowerCase().match(this.ean.toLowerCase())
          },
          value: "ean"
        },
        {
          text: "Name",
          align: "left",
          sortable: false,
          filter: value => {
            if (!this.name) return true
            if (!value) return false
            return value.toLowerCase().match(this.name.toLowerCase())
          },
          value: "name"
        },
        {
          text: "Form",
          align: "left",
          sortable: false,
          filter: value => {
            if (!this.form) return true
            if (!value) return false
            return value.toLowerCase().match(this.form.toLowerCase())
          },
          value: "form"
        },
        {
          text: "Action",
          align: "left",
          sortable: false,
          value: "action"
        },
      ],
      rppItems
    };
  },
  watch:{
    selected(foundProducts){
      const foundIndexes = foundProducts.findIndex(p=>p.campaign === this.campaign)
      if(foundIndexes > -1){
        this.campaignSelectedProducts = [{ean:foundProducts[foundIndexes].barcode, price:foundProducts[foundIndexes].oPrice, count:1}]
      }
    }
  },
  methods: {
    ...mapActions(["snackAlert"]),
    resetFilter() {
      this.brand = "";
      this.ean = "";
      this.name = "";
      this.form = "";
    },
    searchProduct(item, query) {
      return item.brand.toLowerCase().includes(query.toLowerCase()) || item.form.toLowerCase().includes(query.toLowerCase()) || item.name.toLowerCase().includes(query.toLowerCase());
    },
    addProduct(data, status) {
      const product = status ? data : data.item;
      const isFound = this.campaignSelectedProducts.findIndex(item => item.id === product.id);
      if (isFound >= 0) {
        this.campaignSelectedProducts[isFound].count++;
      } else {
        this.campaignSelectedProducts.push({
          brand: product.brand,
          category: product.category,
          country: product.country,
          ean: product.ean,
          form: product.form,
          id: product.id,
          isPgProduct: product.isPgProduct,
          name: product.name,
          count: 1,
          rpp: '',
        });
      }
      this.currentReceipt.products = this.currentReceipt.products || {};
      this.currentReceipt.products[this.productCategory] = this.campaignSelectedProducts;

      // Update total amount
      this.updateTotalAmount()
    },
    removeProduct(data, status) {
      const product = status ? data : data.item;
      const isFound = this.campaignSelectedProducts.findIndex(item => item.id === product.id);
      if (isFound >= 0) {
        if (this.campaignSelectedProducts[isFound].count > 1) {
          this.campaignSelectedProducts[isFound].count--;
        } else {
          this.campaignSelectedProducts.splice(isFound, 1);
        }

        // Update total amount
        this.updateTotalAmount()
      }
      this.currentReceipt.products[this.productCategory] = this.campaignSelectedProducts;
    },
    changePrice(event, product) {
      const isFound = this.campaignSelectedProducts.findIndex(item => item.id === product.id);
      if (isFound >= 0) {
        this.campaignSelectedProducts[isFound].price = event;

        // Update total amount
        this.updateTotalAmount()

        // Check if price is over 38 then show alert
        if (event > 38 && (this.currentReceipt.campaignId == 30164)) {
          this.snackAlert({
            message: `Price should not be more than 38 for this campaign (30164), please inform Mirjana or Katerina.`
          });
        }
      }
      this.currentReceipt.products[this.productCategory] = this.campaignSelectedProducts;
    },
    updateRpp(event, product) {
      const isFound = this.campaignSelectedProducts.findIndex(item => item.id === product.id);
      if (isFound >= 0) {
        this.campaignSelectedProducts[isFound].rpp = event;
      }
      this.currentReceipt.products[this.productCategory] = this.campaignSelectedProducts;
    },
    updateTotalAmount(){
      this.totalAmount = 0
      this.campaignSelectedProducts.forEach(p => {
        if (p.price) this.totalAmount += p.price * p.count
      })
    },
    // getProductName (ean) {
    //   return this.products.find(item => item.ean == parseInt(ean)).name || '';
    // }
  },
  props: {
    productCategory: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Array,
      default: () => []
    }
  }
};
</script>
