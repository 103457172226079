import { axios } from "../plugins/axios";
import axiosNew from "../plugins/axiosNew";
import { axiosWallet } from "../plugins/axiosNew";
import router from "./../router";
import endpointsConfig from "./endpoints";
import { fetchCampaigns } from './campaignData';

const marketingCampaignId = {
  'tr': 'joltx-tr-wallet',
  'gr': 'joltx-gr',
  'de': 'joltx-de',
  'ro': 'joltx-ro-wallet',
  'hu': 'joltx-hu-wallet',
  'pl': 'joltx-pl-wallet',
  'cz': 'joltx-cz',
  'sk': 'joltx-sk',
}

const marketingCampaignIdUserJourney = {
  'tr': 'joltx',
  'gr': 'joltx-gr',
  'de': 'joltx-de',
  'ro': 'joltx-ro',
  'hu': 'joltx-hu',
}

export default {
  errorHandler({ dispatch }, error) {
    const data = error.data ? error.data : null;
    const errorMsg = data.message ? data.message.message : 'Unknown Error'
    if (!error.status) {
      return dispatch("snackAlert", { message: error });
    }
    if (errorMsg == 'Session Expired. Please login again') {
      router.push({ name: "login" });
      dispatch("snackAlert", {
        message: "Authorization Problem. Please login again. ¯\\_(ツ)_/¯"
      });
      return;
    }
    switch (error.status) {
      case 401:
        router.push({ name: "login" });
        dispatch("snackAlert", {
          message: "Authorization Problem. Please login again. ¯\\_(ツ)_/¯"
        });
        break;
      default:
        console.log(error);
        dispatch("snackAlert", { message: errorMsg });
    }
  },

  snackAlert({ commit }, payload) {
    const { message, type = "error", duration = 1500 } = payload;
    commit("snackBar", { message, type, duration });
  },

  login({ commit, dispatch, getters }, value) {
    const { user } = value;
    let { market } = user;

    const dynamicEndpoint = endpointsConfig[market]
    const endpoint = dynamicEndpoint ? dynamicEndpoint.login : null;
    if (!endpoint) throw new Error("Endpoint not found");

    const campaignIds = {
      'tr': '3219',
      'gr': '1788',
      'de': '1886',
      'ro': '30319',
      'hu': '3226',
      'pl': '30425',
      'cz': '30103',
      'sk': '30104',
      'gr_wallet': '30401',
      'hu_wallet': '30057',
      'ro_wallet': '30030',
      'coketest': 'coketest',
    }

    const globalCampaignId = campaignIds[market];
    commit("updateGlobalCampaignId", globalCampaignId);
    return axiosNew
      .post(`/${globalCampaignId}/login`, user)
      .then(res => {
        if (res.data.response) {
          commit("setUserToken", res.data.userToken);
          commit("setUserRole", res.data.level);
          commit("setIsAuthenticated", true);
          commit("setUserName", user.email);
          if (market == 'gr_wallet') market = 'gr';
          if (market == 'hu_wallet') market = 'hu';
          commit("setMarket", market);
          localStorage.isAuthenticated = true;
          localStorage.user = JSON.stringify({
            userName: user.email,
            role: res.data.level,
            userToken: res.data.userToken,
            market: market
          });
          return { role: res.data.level };
        } else {
          dispatch("snackAlert", { message: res.data.message });
        }
      })
      .catch(err => {
        dispatch("errorHandler", err.response);
      });
  },

  logout({ commit }) {
    commit("setUserToken", "");
    commit("setIsAuthenticated", false);
    commit("setUserRole", "");
    commit("setUserName", "");
    commit("setMarket", "");
    localStorage.removeItem("user");
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("token");
    router.push({ name: "login" });
  },

  setTheme({ commit }, value) {
    localStorage.darkTheme = value;
    commit("setDarkTheme", !value);
  },

  dashboarderStatistics({ getters, state, dispatch }) {
    const dynamicEndpoint = endpointsConfig[state.market]
    const endpoint = dynamicEndpoint ? dynamicEndpoint.statistics : null;
    if (!endpoint) throw new Error("Endpoint not found");

    const globalCampaignId = getters.globalCampaignId;
    return axiosNew
      .post(`/${globalCampaignId}${endpoint}`, { userToken: getters.userToken, market: getters.market })
      .then(res => {
        if (res.data.response) {
          return res.data;
        } else {
          dispatch("snackAlert", { message: res.data.message });
        }
      })
      .catch(err => {
        dispatch("errorHandler", err.response);
      });
  },

  getOneReceipt({ state, getters, commit, dispatch }, value) {
    const refEndpoint = value ? value.refEndpoint : null
    const serviceDash = value ? value.serviceDash : null
    const dynamicEndpoint = endpointsConfig[state.market]
    const endpoint = refEndpoint ? dynamicEndpoint[refEndpoint] : dynamicEndpoint.getOneReceipt;
    if (!endpoint) throw new Error("Endpoint not found");

    const globalCampaignId = serviceDash ? dynamicEndpoint[serviceDash] : getters.globalCampaignId;

    return new Promise ((resolve, reject) => {
      axiosNew
      .post(`/${globalCampaignId}${endpoint}`, { userToken: getters.userToken, market: getters.market})
      .then(async res => {
        if (res.data.response) {
          const receipt = res.data.receipt
          receipt.products = receipt.products ? JSON.parse(receipt.products) : {}
          commit("setCurrentReceipt", res.data.receipt);
          if (res.data.receipt.processHistory) commit("setProcessHistory", res.data.receipt.processHistory);
          localStorage.retailers = JSON.stringify(res.data.campaignRetailers);
          dispatch('getDashboardData', receipt.campaignId).then(()=> {
            resolve(res.data)
          })
        } else {
          dispatch("snackAlert", { message: res.data.error });
          reject(res.data.error)
        }
      })
      .catch(err => {
        dispatch("errorHandler", err.response);
        reject(err.response.data)
      });
    })
  },

  getDashboardData({ state, getters, dispatch }, campaignId) {
    const dynamicEndpoint = endpointsConfig[state.market]
    const endpoint = dynamicEndpoint ? dynamicEndpoint.getDashboardData : null;
    if (!endpoint) throw new Error("Endpoint not found");

    const globalCampaignId = getters.globalCampaignId;

    return new Promise((resolve, reject) => {
      axiosNew
        .post(`/${globalCampaignId}${endpoint}`, { userToken: getters.userToken, market: getters.market, campaignId })
        .then(res => {
          if (res.data.response) {
            localStorage.pgProducts = JSON.stringify(res.data.products.pg);
            localStorage.participatingProducts = JSON.stringify(res.data.products.pg);
            localStorage.compProducts = JSON.stringify(res.data.products.competitor);
            localStorage.otherProducts = JSON.stringify(res.data.products.other);
            localStorage.rejectReasons = JSON.stringify(res.data.rejectMessages);
            localStorage.reEvaluationMessages = JSON.stringify(res.data.reclaimReasons);
            resolve(res.data)
          } else {
            dispatch("snackAlert", { message: res.data.message });
            reject(res.data.message)
          }
        })
        .catch(err => {
          dispatch("errorHandler", err.response);
          reject(err.response.data)
        });
    })
  },

  getDashboardDataService({ state, getters, dispatch }, campaignId) {
    const dynamicEndpoint = endpointsConfig[state.market]
    const endpoint = dynamicEndpoint ? dynamicEndpoint.getDashboardDataService : null;
    if (!endpoint) throw new Error("Endpoint not found");

    const defaultCampaignId = dynamicEndpoint ? dynamicEndpoint.defaultServiceDashCampaignId : null

    return new Promise((resolve, reject) => {
      axiosNew
        .post(`/${defaultCampaignId}${endpoint}`, { userToken: getters.userToken, market: getters.market, campaignId })
        .then(res => {
          if (res.data.response) {
            localStorage.pgProducts = JSON.stringify(res.data.products.pg);
            localStorage.participatingProducts = JSON.stringify(res.data.products.pg);
            localStorage.compProducts = JSON.stringify(res.data.products.competitor);
            localStorage.otherProducts = JSON.stringify(res.data.products.other);
            localStorage.rejectReasons = JSON.stringify(res.data.rejectMessages);
            localStorage.reEvaluationMessages = JSON.stringify(res.data.reclaimReasons);
            resolve(res.data)
          } else {
            dispatch("snackAlert", { message: res.data.message });
            reject(res.data.message)
          }
        })
        .catch(err => {
          dispatch("errorHandler", err.response);
          reject(err.response.data)
        });
    })
  },

  processReceipt({ dispatch, state, getters }, value) {
    const { status, payload } = value;

    const dynamicEndpoint = endpointsConfig[state.market]
    const endpoint =
      status === "REJECTED"
        ? "/rejectReceipt"
        : status === "REEVALUATE" && dynamicEndpoint
        ? dynamicEndpoint.reEvaluateReceipt
        : status === "GOODWILL"
        ? "/confirmReceiptGoodwill"
        : status === "REJECTNORUB"
        ? "/rejectReceiptWithoutRubId"
        : status === "REJECTSERVICEDASH"
        ? "/rejectReceiptMoneyBack"
        : status === "CONFIRMNORUB"
        ? "/confirmReceiptWithoutRubId"
        : status === "CONFIRMSERVICEDASH"
        ? "/confirmReceiptMoneyBack"
        : status === "CONFIRMSERVICEDASHNORUB"
        ? "/confirmReceiptServiceDashboardWithoutRubId"
        : status === "UPDATE_RECEIPT"
        ? "/updateReceipt"
        : "/confirmReceipt";

    if (!endpoint) throw new Error("Endpoint not found");

    const globalCampaignId = getters.globalCampaignId;
    const {campaignId} = payload
    return axiosNew
      .post(`/${campaignId ? campaignId : globalCampaignId}${endpoint}`, payload)
      .then(res => {
        if (res.data.response) {
          return res.data;
        } else {
          dispatch("snackAlert", { message: res.data.message });
        }
      })
      .catch(err => {
        dispatch("errorHandler", err.response);
      });
  },
  holdReceipt({ dispatch, state }, value) {
    const { campaignId, payload } = value
    return axiosNew
      .post(`/${campaignId}/holdOn`, payload)
      .then(res => {
        if (res.data.response) {
          return res.data;
        } else {
          dispatch("snackAlert", { message: res.data.message });
        }
      })
      .catch(err => {
        dispatch("errorHandler", err.response);
      });
  },
  dropReceipt({ commit, state, dispatch }, {campaignId, receiptToken}) {
    const dynamicEndpoint = endpointsConfig[state.market]
    const endpoint = dynamicEndpoint ? dynamicEndpoint.dropReceipt : null;
    if (!endpoint) throw new Error("Endpoint not found");

    const userToken = state.userToken;

    return axiosNew
      .post(`${campaignId}${endpoint}`,
        {
          userToken,
          receiptToken
        }
      )
      .then(res => {
        if (res.data.response) {
          return res.data;
        } else {
          dispatch("snackAlert", { message: res.data.message });
        }
      })
      .catch(err => {
        dispatch("errorHandler", err.response);
      });
  },

  getReceiptWithReferenceNumber({ getters, state, commit, dispatch }, value) {
    const referenceNumber = value ? value.referenceNumber : null
    const refEndpoint = value ? value.refEndpoint : null
    const serviceDash = value ? value.serviceDash : null
    const dynamicEndpoint = endpointsConfig[state.market]
    const endpoint = refEndpoint ? dynamicEndpoint[refEndpoint] : dynamicEndpoint.getReferenceNumber;
    if (!endpoint) throw new Error("Endpoint not found");

    const campaignId = serviceDash ? dynamicEndpoint[serviceDash] : getters.globalCampaignId;
    const userToken = getters.userToken
    const market = getters.market

    return new Promise ((resolve, reject) => {
      axiosNew
      .get(`/${campaignId}${endpoint}/${userToken}/${referenceNumber}?market=${market}`)
      .then(res => {
        if (res.data.response) {
          const receipt = res.data.receipt
          receipt.products = receipt.products ? JSON.parse(receipt.products) : {}
          commit("setCurrentReceipt", res.data.receipt);
          commit("setProcessHistory", res.data.receipt.processHistory);
          localStorage.retailers = JSON.stringify(res.data.campaignRetailers);

          if (serviceDash && market === "de") dispatch('getDashboardDataService', receipt.campaignId).then(()=> { resolve(res.data) })
          else dispatch('getDashboardData', receipt.campaignId).then(()=> { resolve(res.data) })
        } else {
          dispatch("snackAlert", { message: res.data.error });
          reject(res.data.error)
        }
      })
      .catch(err => {
        dispatch("errorHandler", err.response);
        reject(err.response.data)
      });
    })
  },

  getReceiptWithReceiptId({ getters, commit, dispatch }, value) {
    const { receiptId, market } = value

    const campaignId = getters.globalCampaignId;
    const userToken = getters.userToken

    return new Promise ((resolve, reject) => {
      axiosNew
      .get(`/${campaignId}/getRubReceiptId/${userToken}/${receiptId}?market=${market}`)
      .then(res => {
        if (res.data.response) {
          const receipt = res.data.receipt
          receipt.products = receipt.products ? JSON.parse(receipt.products) : {}
          commit("setCurrentReceipt", res.data.receipt);
          commit("setProcessHistory", res.data.receipt.processHistory);
          localStorage.retailers = JSON.stringify(res.data.campaignRetailers);
          dispatch('getDashboardData', receipt.campaignId).then(()=> {
            resolve(res.data)
          })
        } else {
          dispatch("snackAlert", { message: res.data.error });
          reject(res.data.error)
        }
      })
      .catch(err => {
        dispatch("errorHandler", err.response);
        reject(err.response.data)
      });
    })
  },

  getReceiptsWithConsumerInfo({ getters, state, dispatch }, value) {
    const dynamicEndpoint = endpointsConfig[state.market]
    const endpoint = dynamicEndpoint ? dynamicEndpoint.login : null;
    if (!endpoint) throw new Error("Endpoint not found");

    return axiosNew
      .post(`/${marketingCampaignId[state.market]}/getReceiptsWithConsumerInfo`, { ...value, userToken: getters.userToken })
      .then(res => {
        if (res.data.response && res.data.receipts && res.data.receipts.length) {
          return res.data;
        } else {
          dispatch("snackAlert", { message: res.data.message });
        }
      })
      .catch(err => {
        dispatch("errorHandler", err.response);
      });
  },

  getConfirmationLinkForUser ({ getters, dispatch }, value) {
    const {receiptToken, campaignId, customerToken} = value
    return axiosNew({
      method: "post",
      url: `${campaignId}/getUserConfirmationLink`,
      data: {
        receiptToken,
        customerToken
      }
    })
    .then(response => {
      if (response.data.response) {
        return response.data.confirmationLink
      }
      dispatch("errorHandler", response);
    })
    .catch(err => {
      dispatch("errorHandler", err.response);
    });
  },
  getConvertedLink ({ getters, dispatch }, value) {
    const {receiptToken, campaignId, customerToken} = value
    return axiosNew({
      method: "post",
      url: `${campaignId}/convertDonationToIbanPayout`,
      data: {
        receiptToken,
        customerToken
      }
    })
    .then(response => {
      if (response.data.response) {
        return response.data.confirmationLink
      }
      dispatch("errorHandler", response);
    })
    .catch(err => {
      dispatch("errorHandler", err.response);
    });
  },

  updateAgentsUploadFile ({ getters, dispatch }, value) {
    const {receiptToken, campaignId, uploadedImageLink} = value
    return axiosNew({
      method: "post",
      url: `${campaignId}/updateAgentsUploadFile`,
      data: {
        receiptToken,
        userToken: getters.userToken,
        uploadedImageLink
      }
    })
    .then(response => {
      if (response.data.response) {
        return response.data.reuploadLink
      }
      dispatch("errorHandler", response);
    })
    .catch(err => {
      dispatch("errorHandler", err.response);
    });
  },

  async createIbanReuploadLink ({ getters, dispatch }, value) {
    const {customerToken, receiptToken, campaignId, sendEmail} = value
    return axiosNew({
      method: "post",
      url: `${campaignId}/createIbanReuploadLink`,
      data: {
        userToken: getters.userToken,
        customerToken,
        receiptToken,
        sendEmail
      }
    })
    .then(response => {
      if (response.data.response) {
        return response.data.reuploadLink
      }
      dispatch("errorHandler", response);
    })
    .catch(err => {
      dispatch("errorHandler", err.response);
    });
  },

  async createProductReuploadLink ({ getters, dispatch }, value) {
    const {customerToken, receiptToken, campaignId, sendEmail} = value
    return axiosNew({
      method: "post",
      url: `${campaignId}/createProductReuploadLink`,
      data: {
        userToken: getters.userToken,
        customerToken,
        receiptToken,
        sendEmail
      }
    })
    .then(response => {
      if (response.data.response) {
        return response.data.reuploadLink
      }
      dispatch("errorHandler", response);
    })
    .catch(err => {
      dispatch("errorHandler", err.response);
    });
  },

  async createReceiptReuploadLink ({ getters, dispatch }, value) {
    const {customerToken, receiptToken, campaignId, sendEmail} = value
    return axiosNew({
      method: "post",
      url: `${campaignId}/createReceiptReuploadLink`,
      data: {
        userToken: getters.userToken,
        customerToken,
        receiptToken,
        sendEmail
      }
    })
    .then(response => {
      if (response.data.response) {
        return response.data.reuploadLink
      }
      dispatch("errorHandler", response);
    })
    .catch(err => {
      dispatch("errorHandler", err.response);
    });
  },

  async sendEmail({ getters, dispatch }, value) {
    const { campaignId, referenceNumber } = value;
    try {
      const response = await axiosNew({
        method: "post",
        url: `${campaignId}/sendMoneyBackMailPerReferenceNumber`,
        data: {
          referenceNumber: referenceNumber,
        },
      });
      if (!response.data.response) { 
        dispatch("errorHandler", response); 
      } 
    } catch (error) {
      dispatch("errorHandler", error.response); 
    }
  },

  async fetchAndSetCampaignsData({ commit }) {
    const data = await fetchCampaigns();
    commit('setCampaignsData', data.parsedCsvContent);
    commit('setCampaignNames', data.parsedCampaignNames)
  },
  sendSecondFlowEmail30days ({ dispatch }, value) {
    const {campaignId, referenceNumber } = value
    return new Promise ((resolve, reject) => {
      axiosNew({
        method: "post",
        url: `${campaignId}/sendMoneyBackMailPerReferenceNumber`,
        data: {
          referenceNumber
        }
      })
      .then(response => {
        if (response.data.response) {
          resolve(response.data)
        }
        reject(response.data)
        dispatch("errorHandler", response);
      })
      .catch(err => {
        reject(err.response.data)
        dispatch("errorHandler", err.response);
      });

    })
  },
  async getWalletAmount ({ dispatch, getters }, value) {
    const {consumerId, campaignId} = value

    return axiosWallet
      .get(`/${marketingCampaignIdUserJourney[getters.market]}/getWallet/${consumerId}`)
      .then(res => {
        if (res.data.currency) {
          return res.data;
        } else {
          dispatch("snackAlert", { message: res.data.message });
        }
      })
      .catch(err => {
        dispatch("errorHandler", err.response);
      });
  },
  async getHoldOnReceipts ({ dispatch, getters }, value) {
    const globalCampaignId = getters.globalCampaignId;

    return axiosNew
      .post(`/${globalCampaignId}/getHoldOnReferences`, {
        userToken: getters.userToken
      })
      .then(res => {
        if (res.data.response) {
          return res.data.referenceNumberData;
        }
      })
      .catch(err => {
        dispatch("errorHandler", err.response);
      });
  },
  async moveHoldOnToPreConfirmed ({ dispatch, getters }, value) {
    const globalCampaignId = getters.globalCampaignId;

    return axiosNew
      .post(`/${globalCampaignId}/moveHoldOnToPreConfirmed`, {
        userToken: getters.userToken,
        receiptTokensToUpdate: value.receiptTokensToUpdate
      })
      .catch(err => {
        dispatch("errorHandler", err.response);
      });
  },
  async getPaymentDate ({ dispatch, getters }) {
    const globalCampaignId = getters.globalCampaignId;

    return axiosNew
      .post(`/${globalCampaignId}/getPaymentDate`, {
        userToken: getters.userToken
      })
      .then(res => {
        if (res.data.response) {
          return res.data.paymentDates;
        }
      })
      .catch(err => {
        dispatch("errorHandler", err.response);
      });
  },
  async createAManualParticipation ({ dispatch, getters }, value) {
    const {consumerId, imageFile, campaignId} = value

    const formData = new FormData();
    formData.append('receipt', imageFile);
    formData.append('consumerId', consumerId);

    return axiosNew
      .post(`/${campaignId}/createAManualParticipation`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      .then(res => {
        if (res.data.response) {
          return res.data.referenceNumber;
        }
      })
      .catch(err => {
        dispatch("errorHandler", err.response);
      });
  },
};
