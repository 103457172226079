<template>
  <v-card>
    <v-card-text>
      <div>
        <v-btn
          color="primary"
          block
          @click="$emit('apIntervalEnd')"
          :loading="autoPilotMode"
          :disabled="autoPilotMode"
        >
          Click Here To See A Receipt
          <template v-slot:loader>
            <span>Waiting for receipts ({{ remainingTime }})</span>
          </template>
        </v-btn>
        <countdown
          v-if="autoPilotMode"
          :time="countDown"
          :interval="1000"
          @end="handleCountdownEnd"
          @progress="handleCountdownProgress"
        >
          <v-progress-linear :value="progress" color="blue accent-4" />
        </countdown>
      </div>
      <v-switch v-model="autoPilotMode" label="Autopilot" />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      countDown: 2000,
      progress: 100,
      autoPilotIntervalDefault: 2000,
      counting: false,
      secondsLeft: 2000,
      receiptInterval: 0
    };
  },
  computed: {
    ...mapGetters(["autoPilotMode"]),
    remainingTime() {
      return ((this.countDown / 1000) * this.progress) / 100;
    },
    autoPilotMode: {
      get() {
        return this.$store.state.autoPilotMode;
      },
      set(value) {
        this.$store.commit("setAutoPilotMode", value);
      }
    }
  },
  watch: {
    autoPilotMode(v) {
      if (v) {
        this.countDown = this.autoPilotIntervalDefault;
        this.$store.commit("setAutoPilotMode", true);
        this.handleCountdownProgress({
          totalSeconds: this.autoPilotIntervalDefault / 1000
        });
      }
      this.counting = v;
    }
  },
  destroyed() {
    clearInterval(this.receiptInterval);
  },
  methods: {
    handleCountdownEnd() {
      this.handleCountdownProgress({
        totalSeconds: 0
      });
      this.$nextTick(() => {
        this.$emit("apIntervalEnd");
        this.autoPilotMode = false;
        // this.$nextTick(() => {
        //   this.autoPilotSwitch = true
        // })
      });
    },
    handleCountdownProgress(data) {
      this.secondsLeft = data.totalSeconds;
      this.progress = Math.floor((100 * this.secondsLeft) / (this.countDown / 1000));
    }
  }
};
</script>
