<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <v-card-text>
          <v-form
            ref="form"
            v-model="valid"
            @keyup.native.enter="loginUser"
          >
            <v-text-field
              v-model="userName"
              outlined
              label="Username"
              placeholder="username"
              hide-details
              class="mb-3"
              :rules="[v => v.length >= 3]"
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              class="mb-3"
              @click:append="isPasswordVisible = !isPasswordVisible"
              :rules="[
                v => !!v || 'Login.passwordRequired',
                v => v.length >= 6 || 'Login.passwordMoreThen',
                v => v.length <= 16 || 'Login.passwordLessThen'
              ]"
            ></v-text-field>

            <v-select
              v-model="market"
              :items="markets"
              label="Market"
              item-text="name"
              item-value="value"
              outlined
              :rules="[
                v => !!v,
              ]"
            ></v-select>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <a
                href="/forgotPassword"
                class="mt-1"
              > Forgot Password? </a>
            </div>

            <v-btn
              block
              color="primary"
              class="mt-6"
              @click="loginUser"
            >
              Login
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
    <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from "@mdi/js";
import { ref } from "vue"
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions(["login", "snackAlert"]),
    loginUser() {
      if (this.$refs.form.validate()) {
        this.login({
          user: {
            email: this.userName,
            password: this.password,
            market: this.market
          }
        }).then(result => {
          if (result.role == "VIEWER" && this.market != "hu" && this.market != "gr") {
            this.snackAlert({
              message: "You are not authorized to access as a viewer",
              type: "error"
            });
            return;
          }
          if (["ADMIN", "OPERATOR", "TESTER"].includes(result.role)) {
            this.$router.push({
              name: "dashboard"
            });
          } else if (["AGENT", "AGENTL1", "VIEWER"].includes(result.role)) {
            this.$router.push({
              name: "agent"
            });
          } else if (["SERVICEOP", "SERVICEOPDACH"].includes(result.role)){
            this.$router.push({
              name: "service"
            });
          }
        }).catch(err => {
          this.snackAlert({
            message: "You are not authorized to access this page",
            type: "error"
          });
        })
      }
    }
  },
  setup() {
    const isPasswordVisible = ref(false);
    const userName = ref('');
    const password = ref('');
    const market = ref('');
    const markets = [
      { name: 'Turkey', value: 'tr' },
      { name: 'DACH', value: 'de' },
      { name: 'Greece', value: 'gr' },
      { name: 'Greece Wallet', value: 'gr_wallet' },
      { name: 'Romania', value: 'ro' },
      { name: 'Romania Wallet', value: 'ro_wallet' },
      { name: 'Hungary', value: 'hu' },
      { name: 'Hungary Wallet', value: 'hu_wallet' },
      { name: 'Poland', value: 'pl' },
      { name: 'Czech', value: 'cz' },
      { name: 'Slovakia', value: 'sk' },
      { name: 'Coke Test', value: 'coketest' },
    ]
    return {
      isPasswordVisible,
      userName,
      password,
      markets,
      market,
      valid: true,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline
      }
    };
  }
};
</script>

<style lang="scss">
@import "~@/plugins/vuetify/default-preset/preset/pages/auth.scss";
</style>
