<template>
  <div>
    <div class="title mb-3">
      Create Reupload Link
    </div>
    <v-row>
      <v-col cols="6">
        <v-btn
          :disabled="this.pendingForLink"
          color="orange darken-4"
          @click="getReUploadLink('Receipt')"
        >
          Receipt Reupload Link Create
        </v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn
          :disabled="this.pendingForLink"
          color="orange darken-4"
          @click="getReUploadLink('Product')"
        >
          Product Reupload Link Create
        </v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn
          v-if="!hideIban()"
          :disabled="this.pendingForLink"
          color="orange darken-4"
          @click="getReUploadLink('iban')"
        >
          IBAN Reupload Link Create
        </v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn
          v-if="!hideIban()"
          :disabled="this.pendingForLink"
          color="orange darken-4"
          @click="_getConfirmationLinkForUser()"
        >
          Get confirmation link (payout page)
        </v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn
          v-if="['hu', 'cz', 'sk', 'pl'].includes(market)"
          :disabled="this.pendingForLink"
          color="orange darken-4"
          @click="sendMoneybackEmail()"
        >
          Send Moneyback Mail
        </v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn
          v-if="market == 'hu'"
          :disabled="this.pendingForLink"
          color="orange darken-4"
          @click="convertToCashback()"
        >
          Convert Donations To Iban Payout
        </v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn
          v-if="market == 'hu'"
          :disabled="this.pendingForLink"
          color="orange darken-4"
          @click="_sendSecondFlowEmail30days()"
        >
          Send 2nd Flow Email (30 days)
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="6">
        <v-checkbox
          v-model="shouldSendEmail"
          label="Should send email"
          color="orange darken-4"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <div class="col-sm-12" v-if="this.reuploadLink !== ''">
        <span class="title">Reupload Link:</span>
        <div
          class="form-control wizard-form-control d-flex flex-direction-column align-items-center px-20px mb-10px"
        >
          <p class="text">{{ this.reuploadLink }}</p>
          <v-btn
            style="width: fit-content"
            :color="this.copyColor"
            @click="copyTestingCode()"
          >
            Copy
          </v-btn>
          <input
            type="hidden"
            id="createdReuploadLink"
            :value="reuploadLink"
          />
        </div>
      </div>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";

export default {
  name: "ReuploadLinks",
  data() {
    const origCopyColor = "green";

    return {
      pendingForLink: false,
      reuploadLink: "",
      origCopyColor,
      copyColor: origCopyColor,
      shouldSendEmail: false
    }
  },
  methods: {
    ...mapActions(["snackAlert", "getConfirmationLinkForUser", "createIbanReuploadLink", "createProductReuploadLink", "createReceiptReuploadLink", "getConvertedLink", "sendSecondFlowEmail30days", "sendEmail"]),
    copyTestingCode () {
      navigator.clipboard.writeText(this.reuploadLink);
      this.snackAlert({message: "Copied", type: "success"})
    },
    hideIban () {
      const marketsToHide = {"tr": true}
      if (marketsToHide[this.market]) return true
      return false
    },
    getReUploadLink (type) {

      this.copyColor = "black";
      this.pendingForLink = true;

      const data = {
        customerToken: this.currentReceipt.customerToken,
        receiptToken: this.currentReceipt.receiptToken,
        campaignId: this.currentReceipt.campaignId,
        type,
        sendEmail: this.shouldSendEmail
      }

      const funcPerType = {
        "iban": this.createIbanReuploadLink,
        "Product": this.createProductReuploadLink,
        "Receipt": this.createReceiptReuploadLink
      }

      const response = funcPerType[type](data)
        .then(resp => {
          this.reuploadLink = resp;
        })
        .finally(() => {
          this.pendingForLink = false
          this.copyColor = this.origCopyColor
        })
    },
    _getConfirmationLinkForUser () {

      this.copyColor = "black";
      this.pendingForLink = true;

      const data = {
        customerToken: this.currentReceipt.customerToken,
        receiptToken: this.currentReceipt.receiptToken,
        campaignId: this.currentReceipt.campaignId
      }

      const response = this.getConfirmationLinkForUser(data)
        .then(resp => {
          this.reuploadLink = resp;
        })
        .finally(() => {
          this.pendingForLink = false
          this.copyColor = this.origCopyColor
        })
    },
    convertToCashback () {
      this.copyColor = "black";
      this.pendingForLink = true;

      const data = {
        customerToken: this.currentReceipt.customerToken,
        receiptToken: this.currentReceipt.receiptToken,
        campaignId: this.currentReceipt.campaignId
      }

      const response = this.getConvertedLink(data)
        .then(resp => {
          this.snackAlert({message: "Participation converted to Cashback", type: "success"})
        })
        .finally(() => {
          this.pendingForLink = false
          this.copyColor = this.origCopyColor
        })
    },
    _sendSecondFlowEmail30days () {
      this.pendingForLink = true;

      const data = {
        referenceNumber: this.currentReceipt.referenceNumber,
        campaignId: this.currentReceipt.campaignId
      }

      this.sendSecondFlowEmail30days(data)
        .then(resp => {
          this.snackAlert({message: "Second Flow email sent successfully", type: "success"})
        })
        .finally(() => {
          this.pendingForLink = false
        })
    },
    sendMoneybackEmail () {
      this.pendingForLink = true;
      const data = {
        campaignId: this.currentReceipt.campaignId,
        referenceNumber: this.currentReceipt.referenceNumber
      }
      this.sendEmail(data)
        .then(resp => {
          this.snackAlert({message: "Email sent successfully", type: "success"})
        })
        .finally(() => {
          this.pendingForLink = false
        })
    }
  },
  computed: {
    ...mapGetters(["currentReceipt", "market"])
  }
}
</script>


<style lang="scss" scoped>

button {
  width: 100%;
}

</style>
