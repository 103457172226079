<template>
  <v-menu
    absolute
    v-model="open"
    ref="open"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        outlined
        v-model="dateFormatted"
        :label="label"
        v-on="on"
        :placeholder="format"
        :prepend-icon="icon"
        v-mask="formatForMask"
        :readonly="!writable"
        @input="updateFromInput"
        :required="required"
        :rules="[v => validateAll(v)]"
        :disabled="disabled"
        dense
      ></v-text-field>
    </template>
    <v-date-picker v-model="date" no-title @input="open = false" :max="max" :min="min">
      <v-btn text color="primary" @click="open = false">Cancel</v-btn>
    </v-date-picker>
    <!-- Returns YYYY-MM-DD -->
  </v-menu>
</template>

<script>
import { mask } from "vue-the-mask";

export default {
  directives: { mask },
  props: {
    label: {
      required: true,
      type: String
    },
    value: {
      required: false,
      type: [String, Number]
    },
    icon: {
      required: false,
      type: String
    },
    format: {
      required: false,
      type: String,
      default: "DD/MM/YYYY"
    },
    writable: {
      required: false,
      type: Boolean,
      default: false
    },
    required: {
      required: true,
      type: Boolean,
      default: false
    },
    rules: {
      required: false,
      type: Array
    },
    dateRule: {
      required: false,
      type: Boolean
    },
    max: {
      required: false,
      type: String,
      default: null
    },
    min: {
      required: false,
      type: String,
      default: null
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  computed: {
    formatForMask() {
      let formattedMask = this.format;
      formattedMask = formattedMask.replace(new RegExp("D", "g"), "#");
      formattedMask = formattedMask.replace(new RegExp("M", "g"), "#");
      formattedMask = formattedMask.replace(new RegExp("Y", "g"), "#");
      return formattedMask;
    }
  },
  data: () => ({
    date: "",
    dateFormatted: null,
    open: false
  }),
  methods: {
    formatDate(date) {
      if (!date) return null;
      return this.$moment(new Date(date)).format(this.format);
    },
    deFormatDate(date) {
      if (!date) return null;
      return this.$moment(date, this.format).format("YYYY-MM-DD");
    },
    update() {
      if (!this.date) {
        this.$emit("input", null);
      } else {
        this.$emit("input", this.$moment(new Date(this.date)).format("YYYY-MM-DD"));
      }
    },
    updateFromInput() {
      if (this.dateFormatted.length > 10) return null;
      if (this.dateFormatted.length == 10 && this.$moment(this.dateFormatted, this.format).isValid()) {
        this.date = this.deFormatDate(this.dateFormatted);
      } else {
        this.$emit("input", null);
      }
    },
    dateValidation(date) {
      if (!date) return !this.required;
      date = this.$moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
      const isValid = this.$moment(date, "YYYY-MM-DD").isValid();
      return (
        isValid &&
        this.dateFormatted.length >= 10 &&
        this.$moment(this.min, "YYYY-MM-DD").isSameOrBefore(this.$moment(date)) &&
        this.$moment(this.max, "YYYY-MM-DD").isSameOrAfter(this.$moment(date))
      );
    },
    validateAll(v) {
      if (this.dateRule) {
        const dateValidationBool = this.dateValidation(v);
        if (!dateValidationBool) return "Please pick a valid date";
      }
      return true;
    }
  },
  watch: {
    date: {
      handler: function(val) {
        if (val != "") {
          this.dateFormatted = this.formatDate(val);
        } else {
          this.dateFormatted = "";
        }
        this.update();
      },
      immediate: true
    },
    value: {
      handler: function(val) {
        if (this.$moment(val).isValid()) {
          this.date = this.$moment(new Date(val)).format("YYYY-MM-DD");
        } else {
          this.date = "";
        }
      },
      immediate: true
    }
  }
};
</script>
