<template>
  <v-col v-if="userRole !== 'OPERATOR' && processHistory !== []">
    <v-data-table
      :headers="processHistoryHeaders"
      :disable-pagination="true"
      :hide-default-footer="true"
      :items="processHistory"
      single-expand
      :expanded.sync="expanded"
      show-expand
      dense
    >
      <template #[`header.dashboarder`]="{ header }">
        <span v-if="userRole !== 'AGENTL1'">{{ header.text }}</span>
      </template>
      <template #[`item.date`]="{ item }">
        <span v-if="userRole !== 'AGENTL1'">{{ formatDate(item.date) }}</span>
      </template>
      <template #[`item.dashboarder`]="{ item }">
        <span v-if="userRole !== 'AGENTL1'">{{ item.dashboarder }}</span>
      </template>
      <template #expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          {{ item.reason }}
        </td>
      </template>
    </v-data-table>
  </v-col>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    let processHistory = this.$store.state.processHistory
    processHistory.sort((a, b) => {
      return new Date(a.date) - new Date(b.date);
    });
    processHistory.forEach((element, idx) => {
      element.id = idx
    });
    return {
      expanded: [],
      processHistory,
      processHistoryHeaders: [
        { text: "Date", value: "date" },
        { text: "Dash. ID", value: "dashboarder" },
        { text: "Action", value: "action" },
        { text: "Cause/CB", value: "cause", cellClass: "cause" },
        { value: "data-table-expand" },
      ],
    };
  },
  computed: {
    ...mapGetters(["userRole"])
  },
  methods: {
    formatDate(date) {
      // TODO: This is a temporary fix around the date format issue, because the date format is not consistent (ISO 8601) and it includes timezone information. Other items dont have timezone information.
      if (date.includes('T')) {
        // ISO 8601 format, remove timezone information
        const localDate = date.substring(0, 16); // Extracts 'YYYY-MM-DDTHH:mm'
        return this.$moment(localDate, "YYYY-MM-DDTHH:mm").format("DD/MM/YYYY HH:mm");
      } else {
        return this.$moment(date, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY HH:mm");
      }
    },
  }
};
</script>

<style>
.cause {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
