import axios from 'axios';

function parseCSV(csvContent) {
  const lines = csvContent.split('\n');
  const campaigns = [];

  for (let i = 1; i < lines.length; i++) {
    const cols = lines[i].split(',');
    const campaignId = cols[0].replace(/\"/g, '');
    const startDate = formatDate(cols[1].replace(/\"/g, ''));
    const endDate = formatDate(cols[2].replace(/\"/g, ''));
    const country = cols[3].replace(/\"/g, '').trim();

    campaigns.push({ campaignId, startDate, endDate, country });
  }
  return campaigns;
}

function parseCampaignNames(csvContent){
  function splitLine(line) {
    const result = [];
    let current = '';
    let inQuotes = false;

    for (let i = 0; i < line.length; i++) {
      const char = line[i];
      if (char === '"' && line[i - 1] !== '\\') {
        inQuotes = !inQuotes;
      } else if (char === ',' && !inQuotes) {
        result.push(current);
        current = '';
      } else {
        current += char;
      }
    }
    result.push(current);
    return result.map(field => field.replace(/^"(.*)"$/, '$1')); // Remove surrounding quotes
  }
  const lines = csvContent.trim().split('\n');
  const headers = [
    'campaignId',
    'country',
    'campaignName'
  ];
  console.log("typeof", headers instanceof Array)
  const result = {};

  for (let i = 1; i < lines.length; i++) {
    const obj = {};
    const currentLine = splitLine(lines[i]);
    for (let j = 0; j < headers.length; j++) {
      obj[headers[j]] = currentLine[j];
    }
    result[obj.campaignId] = obj.campaignName;
  }
  return result;
}

function formatDate(dateStr) {
  const [day, month, year] = dateStr.split('.');
  return `${year}-${month}-${day}`;
}

export const fetchCampaigns = async () => {
  const response = await axios.get('https://docs.google.com/spreadsheets/d/1gcXRmqMwPP9xGyYWBgyBUXN_iJXEdhAyTJV2qIRyni4/gviz/tq?tqx=out:csv&sheet=0');
  const campaignNamesResponse = await axios.get('https://docs.google.com/spreadsheets/d/1gcXRmqMwPP9xGyYWBgyBUXN_iJXEdhAyTJV2qIRyni4/gviz/tq?tqx=out:csv&sheet=Campaign%20names')
  const csvContent = response.data;
  const campaignNamesCsv = campaignNamesResponse.data
  const parsedCampaignNames = parseCampaignNames(campaignNamesCsv)
  const parsedCsvContent = parseCSV(csvContent)
  return {parsedCsvContent, parsedCampaignNames};
}
