<template>
  <v-app>
    <v-container>
      <v-form ref="form" @submit.prevent="submitForm">
        <v-file-input
          v-model="imageFile"
          label="Upload Image"
          prepend-icon="mdi-camera"
          accept="image/*"
        ></v-file-input>

        <v-text-field
          v-model="consumerId"
          label="Consumer Id"
          required
        ></v-text-field>

        <v-text-field
          v-model="campaignId"
          label="Campaign ID"
          required
        ></v-text-field>

        <v-btn :disabled="!isValid" color="primary" type="submit">
          Create Participation
        </v-btn>

        <v-text-field
          v-model="referenceNumber"
          label="Reference Number"
          readonly
          append-outer-icon="mdi-content-copy"
          @click:append-outer="copyReference"
        ></v-text-field>
      </v-form>
    </v-container>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data: () => ({
    imageFile: null,
    consumerId: '',
    referenceNumber: '',
    campaignId: '',
    isValid: false,
  }),
  watch: {
    imageFile(newValue) {
      this.isValid = newValue && this.consumerId;
    },
    consumerId(newValue) {
      this.isValid = newValue && this.imageFile;
    },
    campaignId(newValue) {
      this.isValid = newValue && this.consumerId;
    }
  },
  methods: {
    ...mapActions(["createAManualParticipation"]),
    submitForm() {
      if (!this.isValid) return;

      // Simulate API call
      this.referenceNumber = 'Loading...';

      this.createAManualParticipation({
        imageFile: this.imageFile,
        consumerId: this.consumerId,
        campaignId: this.campaignId
      }).then(res => {
        console.log(res)
        this.referenceNumber = res;
      })

    },
    copyReference() {
      navigator.clipboard.writeText(this.referenceNumber)
        .then(() => alert('Copied!'))
        .catch(err => console.error('Error copying text: ', err));
    }
  }
};
</script>

