var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"auth-wrapper auth-v1"},[_c('div',{staticClass:"auth-inner"},[_c('div',{staticClass:"image_block"},[_c('img',{attrs:{"src":require(`@/assets/images/misc/js-logo.png`),"alt":"Justsnap"}})]),_c('v-card',{staticClass:"auth-card"},[_c('v-card-text',[_c('v-form',{ref:"form",nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.loginUser.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"outlined":"","type":_vm.isPasswordVisible ? 'text' : 'password',"label":"Password","placeholder":"············","append-icon":_vm.isPasswordVisible ? _vm.icons.mdiEyeOffOutline : _vm.icons.mdiEyeOutline,"hide-details":"","rules":[
              (v) => !!v || 'Login.passwordRequired',
              (v) => v.length >= 6 || 'Login.passwordMoreThen',
              (v) => v.length <= 16 || 'Login.passwordLessThen',
            ]},on:{"click:append":function($event){_vm.isPasswordVisible = !_vm.isPasswordVisible}},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}}),_c('v-text-field',{staticClass:"mt-3",attrs:{"outlined":"","type":_vm.isPasswordVisible ? 'text' : 'password',"label":"Password Confirm","placeholder":"············","append-icon":_vm.isPasswordVisible ? _vm.icons.mdiEyeOffOutline : _vm.icons.mdiEyeOutline,"hide-details":"","rules":[
              (v) => !!v || 'Login.passwordRequired',
              (v) => v.length >= 6 || 'Login.passwordMoreThen',
              (v) => v.length <= 16 || 'Login.passwordLessThen',
            ]},on:{"click:append":function($event){_vm.isPasswordVisible = !_vm.isPasswordVisible}},model:{value:(_vm.newPasswordConfirm),callback:function ($$v) {_vm.newPasswordConfirm=$$v},expression:"newPasswordConfirm"}}),_c('v-btn',{staticClass:"mt-6",attrs:{"block":"","color":"primary"},on:{"click":_vm.resetUserPassword}},[_vm._v(" Reset Password ")])],1)],1)],1)],1),_c('img',{staticClass:"auth-mask-bg",attrs:{"height":"173","src":require(`@/assets/images/misc/mask-${_vm.$vuetify.theme.dark ? 'dark' : 'light'}.png`)}}),_c('v-img',{staticClass:"auth-tree",attrs:{"width":"247","height":"185","src":require("@/assets/images/misc/tree.png")}}),_c('v-img',{staticClass:"auth-tree-3",attrs:{"width":"377","height":"289","src":require("@/assets/images/misc/tree-3.png")}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }