<template>
  <v-row justify="space-around">
    <v-col
      v-if="!filterRole(['SERVICEOP', 'SERVICEOPDACH'])"
    >
      <v-btn
        :disabled="disableButton"
        @click="holdOnReceipt"
        color="warning"
      >
        Skip
      </v-btn>
    </v-col>
    <v-col
      v-if="!filterRole(['AGENTL1', 'SERVICEOP', 'SERVICEOPDACH'])"
    >
      <v-btn
        :disabled="disableButton"
        @click="startProcessReceipt('REJECTED')"
        color="error"
      >
        Reject
      </v-btn>
    </v-col>
    <v-col
      v-if="!filterRole(['AGENTL1', 'OPERATOR', 'SERVICEOP', 'SERVICEOPDACH'])"
    >
      <v-btn
        :disabled="disableButton"
        @click="startProcessReceipt('REJECTNORUB')"
        color="error"
      >
        Reject (No RUB)
      </v-btn>
    </v-col>
    <v-col
      v-if="!filterRole(['AGENT', 'AGENTL1', 'OPERATOR'])"
    >
      <v-btn
        :disabled="disableButton"
        @click="startProcessReceipt('REJECTSERVICEDASH')"
        color="error"
      >
        Reject (Service Dashboard)
      </v-btn>
    </v-col>
    <v-col
      v-if="filterRole(['AGENTL1'])"
    >
      <v-btn
        :disabled="disableButton"
        @click="startProcessReceipt('REEVALUATE')"
        color="info"
      >
        Re-Evaluate
      </v-btn>
    </v-col>
    <v-col
      v-if="!filterRole(['AGENTL1', 'SERVICEOP', 'SERVICEOPDACH'])"
    >
      <v-btn
        :disabled="disableButton"
        @click="startProcessReceipt('CONFIRMED')"
        color="success"
      >
        Confirm
      </v-btn>
    </v-col>
    <v-col
      v-if="!filterRole(['AGENTL1', 'OPERATOR', 'SERVICEOP', 'SERVICEOPDACH'])"
    >
      <v-btn
        :disabled="disableButton"
        @click="startProcessReceipt('CONFIRMNORUB')"
        color="success"
      >
        Confirm (No RUB)
      </v-btn>
    </v-col>
    <v-col
      v-if="!filterRole(['AGENTL1', 'OPERATOR', 'SERVICEOPDACH']) && !filterRegion('de', 'tr')"
    >
      <v-btn
        :disabled="disableButton"
        @click="startProcessReceipt('CONFIRMSERVICEDASHNORUB')"
        color="success"
      >
        Confirm Service Dash (No RUB)
      </v-btn>
    </v-col>
    <v-col
      v-if="!filterRole(['AGENT', 'AGENTL1', 'OPERATOR'])"
    >
      <v-btn
        :disabled="disableButton"
        @click="startProcessReceipt('CONFIRMSERVICEDASH')"
        color="success"
      >
        Confirm (Service Dashboard)
      </v-btn>
    </v-col>
    <v-col
      v-if="filterRole(['AGENT'])"
    >
      <v-btn
        :disabled="disableButton"
        @click="startProcessReceipt('GOODWILL')"
        color="info"
      >
        Accept Goodwill
      </v-btn>
    </v-col>
    <v-col
      v-if="filterRole(['AGENT'])"
    >
      <v-btn
        :disabled="disableButton"
        @click="startProcessReceipt('UPDATE_RECEIPT')"
        color="info"
      >
        Update Receipt
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    const user = JSON.parse(localStorage.getItem("user"));
    const rejectReasons = localStorage.rejectReasons ? JSON.parse(localStorage.rejectReasons) : [];
    const userRole = user.role;
    const market = user.market
    return {
      rejectReasons,
      userRole,
      disableButton: false,
      market
    };
  },
  computed: {
    ...mapGetters(["currentReceipt", "userToken"])
  },
  inject: ['validateForm'],
  methods: {
    ...mapActions(["processReceipt", "snackAlert", "holdReceipt"]),
    filterRole(roles) {
      return roles.includes(this.userRole);
    },
    filterRegion(regions) {
      return regions.includes(this.market)
    },
    startProcessReceipt(status) {
      // Validate with the inject method
      const valid = this.validateForm()
      if (!valid && (status === "CONFIRMED" || status === "GOODWILL" || status === "CONFIRMNORUB" || status === "CONFIRMSERVICEDASH" || status === "UPDATE_RECEIPT" || status === "CONFIRMSERVICEDASHNORUB")) {
        document.querySelector('#receiptForm').scrollIntoView({ behavior: 'smooth' });
        return
      }
      this.disableButton = true;
      if (status === "CONFIRMED" || status === "GOODWILL" || status === "CONFIRMNORUB" || status === "CONFIRMSERVICEDASH" || status === "UPDATE_RECEIPT" || status === "CONFIRMSERVICEDASHNORUB") {
        const required = ["receiptId", "country", "retailer", "total", "receiptDateTime"];
        const missing = required.filter(x => !this.currentReceipt[x]);
        if (missing.length > 0) {
          this.snackAlert({
            message: `Missing ${missing.join(", ")}`
          });
          this.disableButton = false;
          return;
        }
        if (this.currentReceipt.retailer === 15) {
          this.snackAlert({
            message: `Missing retailer`
          });
          this.disableButton = false;
          return;
        }
      } else if (status === "REEVALUATE") {
        if (!this.currentReceipt.reevaluateReason) {
          this.snackAlert({
            message: `Missing re evaluation reason`
          });
          this.disableButton = false;
          return;
        }
      } else {
        if (!this.currentReceipt.rejectReason && status !== "REJECTSERVICEDASH") {
          this.snackAlert({
            message: `Missing reject reason`
          });
          this.disableButton = false;
          return;
        } else if (!this.rejectReasons.find(x => x.key == this.currentReceipt.rejectReason) && status !== "REJECTSERVICEDASH") {
          this.snackAlert({
            message: `Reject reason not selected`
          });
          this.disableButton = false;
          return;
        }
      }

      let payload =
        status === "REJECTED" || status === "REJECTNORUB" || status === "REJECTSERVICEDASH"
          ? {
              rejectReason: this.currentReceipt.rejectReason,
            }
          : status === "REEVALUATE"
          ? {
              reason: this.currentReceipt.reevaluateReason,
              reClaimText: this.currentReceipt.otherReason,
            }
          : {
              receiptDateTime: this.currentReceipt.receiptDateTime,
              date: this.currentReceipt.receiptDate,
              time: this.currentReceipt.receiptTime,
              receiptId: this.currentReceipt.receiptId,
              total: this.currentReceipt.total.toString(),
              country: this.currentReceipt.country,
              retailer: this.currentReceipt.retailer,
              location: this.currentReceipt.location,
              products: this.currentReceipt.products,
              otherProducts: this.currentReceipt.otherProducts,
            };

      payload = {
        ...payload,
        receiptToken: this.currentReceipt.receiptToken,
        userToken: this.userToken,
        campaignId: this.currentReceipt.campaignId
      };

      this.processReceipt({
        status,
        payload
      }).then(res => {
        this.disableButton = false;
        if (res.response) {
          this.$store.commit("setCurrentReceipt", {});
          this.$store.commit("setShowDialogue", false);
          this.$store.commit("setAutoPilotMode", true);
          this.snackAlert({
            message: `Receipt ${status}`,
            type: "success"
          });
        } else {
          this.snackAlert({
            message: `Error ${status}`
          });
        }
      });
    },
    holdOnReceipt () {
      this.disableButton = true;
      const data = {
        payload: {
          receiptToken: this.currentReceipt.receiptToken,
          userToken: this.userToken,
        },
        campaignId: this.currentReceipt.campaignId
      };

      this.holdReceipt(data).then(res => {
        this.disableButton = false;
        if (res.response) {
          this.$store.commit("setCurrentReceipt", {});
          this.$store.commit("setShowDialogue", false);
          this.$store.commit("setAutoPilotMode", true);
          this.snackAlert({
            message: `Receipt on hold`,
            type: "success"
          });
        } else {
          this.snackAlert({
            message: `Error on hold`
          });
        }
      });
    }
  }
};
</script>
