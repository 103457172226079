import _axios from "axios";

const axiosInstance = _axios.create({
  baseURL: "https://dashboard-be.justsnap.eu"
});

const axiosWallet = _axios.create({
  baseURL: "https://justsnap-backend.justsnap.eu"
})

export default axiosInstance;
export { axiosWallet };
